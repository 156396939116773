<template>
  <el-container v-cloak>
    <el-header height="73px" v-if="!$route.meta.hideHeader">
      <TopSidebar></TopSidebar>
    </el-header>
    <el-container>
      <el-main>
        <router-view></router-view>

        <el-backtop class="el-backtop-1" :visibility-height="200" @click="goBack" title="">
          <img :src="require('@/assets/index/icon/a-1_fanhuiqianye.svg')" alt="返回前页" title="返回前页" />
        </el-backtop>
        <el-backtop class="el-backtop-2" :visibility-height="200" title="">
          <img :src="require('@/assets/index/icon/a-1_fanhuidingbu.svg')" alt="返回顶部" title="返回顶部" />
        </el-backtop>
      </el-main>
      <el-footer height="350px" v-if="!$route.meta.hideHeader">
        <BottomSidebar></BottomSidebar>
      </el-footer>
    </el-container>
  </el-container>
</template>

<script>
import TopSidebar from "@/components/TopSidebar";
import BottomSidebar from "@/components/BottomSidebar";

export default {
  name: "App",
  components: { BottomSidebar, TopSidebar },
  methods: {
    goBack() {
      this.$router.back();
    },
    async validToken() {
      let response = await this.$apiUtil.userApi.validToken(
        this.$store.state.access_token
      );

      if (response.returnCode !== 1) {
        this.errorMsg = response.description;
        this.$store.commit("logout");
      }
    },
  },
  created() {
    // 在页面加载时，读取localStorage里的state
    if (localStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(localStorage.getItem("store"))
        )
      );
      if (this.$store.state.IsLogin) {
        this.validToken();
      }
    }
    //在页面刷新、隐藏时将vuex里的信息保存到 localStorage 里
    window.addEventListener("pagehide", () => {
      localStorage.setItem("store", JSON.stringify(this.$store.state));
    });

    this.$apiUtil.postLog(3)
  },
};
</script>

<style lang="less">
* {
  user-select: none;
  /*火狐*/
  -moz-user-select: none;
  /*谷歌、Safari*/
  -webkit-user-select: none;
  /*IE10*/
  -ms-user-select: none;
  /*欧朋*/
  -o-user-select: none;
  /*早期浏览器*/
  -khtml-user-select: none;
}

#app {
  font-family: SourceHanSerifCN-Regular, Avenir, Helvetica, Arial, sans-serif !important;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #1c425e;
}

body {
  margin: 0;
}

//input, textarea, keygen, select, button, span, li {
//  font-family: SourceHanSerifCN-Regular, Avenir, Helvetica, Arial, sans-serif !important;
//}

.el-header {
  padding: 0 !important;
  position: fixed;
  width: 100%;
  z-index: 999;
}

.el-main {
  top: 0;
  position: relative;
  padding: 0 !important;
  width: 100%;
  overflow: hidden !important;
  // background: url("./assets/common/article/bg_all_buttom.png") no-repeat bottom;

  //回到顶部 按钮
  .el-backtop {
    width: 50px;
    height: 50px;
    right: 64px !important;
    border-radius: initial;
    background: url("./assets/common/BottomSidebar/back_bg.png") no-repeat center;
    background-size: 50px 50px;

    img {
      width: 60px;
      height: 60px;
    }
  }

  .el-backtop-1 {
    bottom: 220px !important;
  }

  .el-backtop-2 {
    bottom: 150px !important;
  }

  .el-backtop:hover {
    background: url("./assets/common/BottomSidebar/back_bg.png") no-repeat center;
    background-size: 50px 50px;
  }
}

.el-footer {
  position: relative;
  padding: 0 !important;
  width: 100%;
  background: #000000;
}

img,
video {
  max-width: 100% !important;
}
</style>
