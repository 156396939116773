<!--
 * @Author: your name
 * @Date: 2021-08-28 14:29:58
 * @LastEditTime: 2021-08-30 10:16:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /szpwz_official_web/src/views/user/center/index.vue
-->
<template>
  <div class="main">
    <el-row>
      <el-col :span="3"></el-col>
      <el-col :span="18">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>个人中心</el-breadcrumb-item>
        </el-breadcrumb>

        <!--   标题   -->
        <div class="title">
          <span class="title_zh">个人中心</span>
          <span class="title_en">Personal Center</span>
        </div>

        <div class="content">
          <div class="left">
            <div class="head-info">
              <el-image class="head-img" :src="$store.state.userInfo.head_url">
                <template v-slot:error>
                  <img :src="require('@/assets/user/default_head_img.png')"/>
                </template>
              </el-image>
              <div class="mobile">{{ $store.state.userInfo.phone_num }}</div>
            </div>
            <div class="menu">
              <ul class="menu-ul">
                <li
                    class="menu-ul-li"
                    :class="{ active: active_menu_index == index }"
                    v-for="(item, index) in left_menu"
                    :key="index"
                    @click="handelLeftMenuClick(index)"
                >
                  <div
                      class="active-left"
                      v-show="active_menu_index == index"
                  ></div>

                  <div class="menu-right">
                    <img
                        :src="item.icon_active"
                        v-if="active_menu_index == index"
                    />
                    <img :src="item.icon" v-else/>
                    <span>{{ item.name }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="right">
            <div class="right-title">
              <div class="bg-begin"></div>
              {{ active_menu_name }}
            </div>
            <div class="right-content">
              <router-view></router-view>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="3"></el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  name: "userCenter",
  data() {
    return {
      active_menu_index: 0,
      active_menu_name: "",
      left_menu: [
        // {
        //   name: "我的订单",
        //   icon: require("@/assets/user/icons/no_active_1.svg"),
        //   icon_active: require("@/assets/user/icons/active_1.svg"),
        //   path: "/user/center/myGoodsOrder",
        // },
        // {
        //   name: "我的预约",
        //   icon: require("@/assets/user/icons/no_active_2.svg"),
        //   icon_active: require("@/assets/user/icons/active_2.svg"),
        //   path: "/user/center/myTicketOrder",
        // },
        {
          name: "在线互动",
          icon: require("@/assets/user/icons/no_active_3.svg"),
          icon_active: require("@/assets/user/icons/active_3.svg"),
          path: "/user/center/onlineInteraction",
        },
        // {
        //   name: "我的投诉",
        //   icon: require("@/assets/user/icons/no_active_5.svg"),
        //   icon_active: require("@/assets/user/icons/active_5.svg"),
        //   path: "/user/center/touristComplaint",
        // },
        {
          name: "账号信息",
          icon: require("@/assets/user/icons/no_active_4.svg"),
          icon_active: require("@/assets/user/icons/active_4.svg"),
          path: "/user/center/account",
        },
      ],
    };
  },
  methods: {
    handelLeftMenuClick(index) {
      this.active_menu_index = index;
      this.active_menu_name = this.left_menu[index].name;
      this.$router.push(this.left_menu[index].path);
    },
    resetLeftMenuActive() {
      const path = this.$route.path;
      let index = this.left_menu.findIndex((menu) => menu.path == path);
      if (index == -1) {
        index = 0;
      }
      this.handelLeftMenuClick(index);
    },
  },
  mounted() {
    if (this.$store.state.IsLogin) {
      this.resetLeftMenuActive();
    } else {
      this.$message("请先登录")
      this.$router.push("/user/login")
    }
  },
};
</script>
<style lang="less" scoped>
.main {
  height: 100%;
  padding-top: 73px;
  padding-bottom: 200px;

  .el-breadcrumb {
    font-size: 18px;
    margin-top: 12px;
    margin-bottom: 20px;
  }

  .title {
    display: inline-grid;
    min-width: 500px;
    height: 70px;
    background: url("../../../assets/common/article/banner_article_node_bg.png") no-repeat center;
    margin-bottom: 60px;

    .title_zh {
      font-size: 36px;
      font-weight: 400;
      color: #1c425e;
    }

    .title_en {
      font-size: 16px;
      font-weight: 400;
      color: #dbdbdb;
    }
  }

  .content {
    // width: 1400px;
    height: 878px;
    background: #ffffff;
    box-shadow: 0px 0px 25px 0px rgba(51, 51, 51, 0.2);
    border-radius: 0px 10px 10px 0px;

    display: flex;

    .left {
      width: 270px;
      height: 878px;
      background: #f8f8f8;
      border-radius: 10px 0px 0px 10px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .head-info {
        height: 280px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .head-img {
          width: 110px;
          height: 110px;
          border-radius: 50%;

          img {
            width: 110px;
          }
        }

        .mobile {
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
        }
      }

      .menu {
        .menu-ul {
          margin: 0;
          padding: 0;

          .menu-ul-li.active {
            background: #ffffff;

            span {
              color: #fdc75a !important;
            }
          }

          .menu-ul-li {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 270px;
            height: 70px;
            cursor: pointer;

            .active-left {
              width: 4px;
              height: 43px;
              background: #fdc75a;
            }

            .menu-right {
              width: 266px;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                width: 20px;
                height: 20px;
              }

              span {
                padding: 5px;
                height: 20px;
                font-size: 16px;
                font-weight: 400;
                color: #2e2e2e;
                text-decoration-line: none;
              }
            }
          }
        }
      }
    }

    .right {
      width: 1160px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .right-title {
        font-size: 22px;
        font-family: SourceHanSansCN-Regular;
        font-weight: 400;
        color: #2e2e2e;
        margin: 40px;

        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;

        .bg-begin {
          width: 6px;
          height: 24px;
          background: #fdc75a;
          border-radius: 3px;
          margin-right: 18px;
        }
      }

      .right-content {
        width: 1040px;
        height: 720px;
        overflow-y: auto;

        padding: 0px 60px 40px 60px;
      }
    }
  }
}
</style>