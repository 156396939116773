/*
 * @Author: your name
 * @Date: 2021-08-27 15:53:45
 * @LastEditTime: 2021-08-29 10:28:19
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /szpwz_official_web/src/api/userApi.js
 */

import axios from './axios'

const
    loginType = 2,
    appid = "wx518448215c1c48ef";

let userApi = {

    /**
     * 账号密码登陆接口
     */
    loginByPhone: async function (phone, password) {
        let result = await axios({
            method: 'POST',
            url: "/api/v1/front/maUser/loginByPhone",
            params: {
                appid: appid,
                loginType: loginType,
                phone: phone,
                password: password
            }
        });
        return result;
    },

    /**
     * 手机、验证码注册接口
     */
    registerByPhone: async function (phone, password, verify_num) {
        let result = await axios({
            method: 'POST',
            url: "/api/v1/front/maUser/registerByPhone",
            params: {
                appid: appid,
                verify_num: verify_num,
                phone: phone,
                password: password
            }
        });
        return result;
    },

    /**
     * 手机、验证码校验接口
     */
    checkPhoneAndCode: async function (phone, verify_num) {
        let result = await axios({
            method: 'POST',
            url: "/api/v1/front/maUser/checkPhoneAndCode",
            params: {
                appid: appid,
                verify_num: verify_num,
                phone: phone,
                loginType: loginType,
            }
        });
        return result;
    },
    /**
     * 手机、验证码登录接口
     */
    loginByPhoneAndCode: async function (phone, verify_num) {
        let result = await axios({
            method: 'POST',
            url: "/api/v1/front/maUser/loginByPhoneAndCode",
            params: {
                appid: appid,
                loginType: loginType,
                phone: phone,
                verify_num: verify_num,
            }
        });
        return result;
    },

    /**
     * 手机、验证码 重置密码接口
     */
    resetPassword: async function (phone, password, verify_num) {
        let result = await axios({
            method: 'POST',
            url: "/api/v1/front/maUser/resetPassword",
            params: {
                appid: appid,
                verify_num: verify_num,
                phone: phone,
                password: password
            }
        });
        return result;
    },
    /**
     * 发送验证码
     */
    sendCode: async function (phone) {
        let result = await axios({
            method: 'POST',
            url: "/api/v1/front/sms/sendCode",
            params: {
                phone: phone
            }
        });
        return result;
    },
    /**
     * 验证 token 是否过期
     */
    validToken: async function (token) {
        let result = await axios({
            method: 'GET',
            url: "/api/v1/token/valid",
            params: {
                token: token
            }
        });
        return result;
    },
    /**
     * 查询本人信息接口
     */
    getMyInfo: async function () {
        let result = await axios({
            method: 'GET',
            url: "/api/v1/wx/maUser/getMyInfo",
            params: {
            }
        });
        return result;
    },
}

export default userApi
