<template xmlns:el-row="http://www.w3.org/1999/html">
  <div class="main-top">
    <div class="banner">
      <el-image v-if="node.top_banner_img_uuid && node.top_banner_img_uuid.length > 0"
        :src="baseApiURL + node.top_banner_img_uuid">
        <template v-slot:error>
          <img loading="lazy" :src="require('../../assets/index/top5.webp')" class="top_img" />
        </template>
      </el-image>
      <!-- <el-image v-else class="top-img" :src="require('@/assets/common/article/banner_article.png')"></el-image> -->
      <span class="top-title">{{ node.node_name }}</span>
    </div>

    <el-row>
      <el-col :span="4"></el-col>
      <el-col :span="16">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ node.parent_node_name }}</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: node.previewURL }">
            {{ node.node_name }}
          </el-breadcrumb-item>
        </el-breadcrumb>

        <!--   标题   -->
        <div v-show="showTitle" class="title">
          <span class="title_zh">
            {{ node.node_name }}
          </span>
          <span class="title_en" v-show="node.node_name_en != ''">
            {{ node.node_name_en }}
          </span>
        </div>

        <!--   描述   -->
        <div v-show="showTitle && node.description !== ''" v-text="node.description" class="content"></div>
      </el-col>
      <el-col :span="4"></el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'list',
  props: {
    showTitle: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      // 显示面包屑导航
      baseApiURL: this.$baseApiURL + '/api/v1/file/download?uuid=',

      node: {
        parent_id: '',
        parent_node_name: '',
        parent_previewURL: '',

        int_id: '',
        node_name: '',
        node_name_en: '',
        description: '',
        previewURL: '',
        top_banner_img_uuid: '',
      },
    }
  },
  methods: {
    async getNodeAndParent() {
      let result = await this.$apiUtil.getNodeAndParent(this.node.int_id)
      console.log(result,11111111)
      if (result.returnCode === 1 && result.result) {
        this.node = result.result
      } else {
        this.node.node_name = '栏目不存在'
        this.$message.error(result.description)
      }
    },
    init() {
      if (this.$route.params.nodeId) {
        this.node.int_id = this.$route.params.nodeId
        this.getNodeAndParent()
      }
    },
  },
  watch: {
    $route() {
      this.init()
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped lang="less">
.main-top {
  .banner {
    width: 100vw;
    min-height: 560px;
    position: relative;

    ::v-deep .el-image {

      .el-image__inner {
        width: 100vw !important;
        height: 560px !important;
      }
    }

    .top_img {
      width: 100vw;
      height: 560px;
    }

    .top-title {
      display: none;
      position: absolute;
      top: 165px;
      left: 78%;
      writing-mode: vertical-lr;
      width: 54px;
      height: 250px;
      font-size: 60px;
      font-family: MaShanZhengMaoBiKaiTi, serif !important;
      font-weight: 400;
      color: #ffffff;
      z-index: 10;
    }
  }

  .el-breadcrumb {
    font-size: 18px;
    margin-top: 12px;
    margin-bottom: 20px;
    width: 200px;
    line-height: 50px;
    position: absolute;
    top: -140px;
    left: 400px;
  }

  ::v-deep .el-breadcrumb__inner {
    color: white;
  }

  ::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner {
    color: white;
    font-size: 40px;
    font-weight: 500;
  }

  ::v-deep .el-breadcrumb__item:nth-child(2) .el-breadcrumb__separator {
    color: transparent;
  }

  .title {
    display: inline-grid;
    min-width: 500px;
    margin-bottom: 60px;
    overflow: hidden;

    .title_zh {
      box-sizing: border-box;
      width: 230px;
      height: 80px;
      line-height: 80px;
      margin-top: 100px;
      margin-left: 150px;
      padding-right: 40px;
      padding-top: 5px;
      font-size: 28px;
      font-weight: 600;
      color: white;
      background-image: url("../../assets/index/overview.jpg");
      background-size: 230px 80px;
    }

    .title_en {
      font-size: 16px;
      font-weight: 400;
      color: #dbdbdb;
    }
  }

  .content {
    //min-height: 100px;
    // 设置为默认值，屏蔽继承父级的center
    text-align: initial;
    text-indent: 2em;
    margin-bottom: 60px;
  }

}
</style>
