<!--
 * @Author: your name
 * @Date: 2021-08-26 20:12:59
 * @LastEditTime: 2021-08-29 14:15:15
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /szpwz_official_web/src/components/VideoPlayer.vue
-->
<template>
  <div>
    <video ref="videoPlayer" class="video-js"></video>
  </div>
</template>

<script>
import videojs from "video.js";

export default {
  name: "VideoPlayer",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      player: null,
    };
  },
  methods: {
    initPlayer() {
      this.player = videojs(
        this.$refs.videoPlayer,
        this.options,
        function onPlayerReady() {
          // console.log("onPlayerReady");
        }
      );
    },
    src(src) {
      if (this.player) {
        this.player.src(src);
      }
    },
    pause() {
      if (this.player) {
        this.player.pause();
      }
    },
    play() {
      if (this.player) {
        this.player.load();
        this.player.play();
      }
    },
    dispose() {
      if (this.player) {
        this.player.dispose();
      }
    },
  },
  mounted() {
    this.initPlayer();
  },
  beforeUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>
<style scoped lang="less">
.video-js {
  max-width: 100%;
  max-height: 100%;
}
</style>