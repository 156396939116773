<template>
  <el-empty :description="title"></el-empty>
</template>

<script>
export default {
  name: "404",
  data() {
    return {
      title: '您访问的页面不存在'
    }
  }

}
</script>

<style scoped lang="less">
.el-empty {
  padding: 300px 0 200px 0;
}
</style>
