import axios from './axios'

let yzGoodsApi = {
    /**
     * 获取商品分组列表
     */
    getGoodsTagList: async function (type, keyword) {
        let result = await axios({
            method: 'GET',
            url: "/api/v1/front/yzGoodsTag/getList",
            params: {
                type: type,
                keyword: keyword
            }
        });
        return result;
    },
    /**
     * 获取商品列表
     */
    getGoodsList: async function (currentPage, pageSize, goods_tag_id, keyword) {
        let result = await axios({
            method: 'GET',
            url: "/api/v1/front/ecommerceGoods/getListByPage",
            params: {
                currentPage: currentPage,
                pageSize: pageSize,
                goods_tag_id: goods_tag_id,
                keyword: keyword
            }
        });
        return result;
    },
    /**
     * 查询本人订单
     */
    getYzOrderListForVisitor: async function (currentPage, pageSize,  status) {
        let result = await axios({
            method: 'GET',
            url: "/api/v1/wx/yzOrderInfoForVisitor/getListByPage",
            params: {
                currentPage: currentPage,
                pageSize: pageSize,
                status: status
            }
        });
        return result;
    },
    /**
     * 获取动态商品菜单
     */
    getGoodsCategories: async function () {
        let result = await axios({
            method: 'GET',
            url: "/api/v1/front/common/getGoodsCategories",
        });
        return result;
    },
}



export default yzGoodsApi