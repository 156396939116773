/*
 * @Author: your name
 * @Date: 2021-08-23 15:18:35
 * @LastEditTime: 2021-08-30 10:00:52
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /szpwz_official_web/src/api/apiUtil.js
 */
import axios from './axios';

import yzGoodsApi from './yzGoodsApi';
import bigDataApi from './bigDataApi';
import userApi from './userApi';
import ticketApi from './ticketApi';
import communicateApi from './communicateApi';
import complainApi from './complain';
import file from './file';

export default {

    yzGoodsApi,
    bigDataApi,
    userApi,
    ticketApi,
    communicateApi,
    complainApi,
    file,

    getNodeList: async function (parentId, deep) {
        let result = await axios.get("/api/v1/front/node/getNodeTreeByParentId?parentId=" + parentId + "&deep=" + deep);
        return result;
    },
    getNodeAndParent: async function (nodeId) {
        let result = await axios.get("/api/v1/front/node/getNodeAndParent?int_id=" + nodeId);
        return result;
    },
    getCommonArticlePage: async function (nodeId, currentPage, pageSize, keyword, need_content) {
        let param = "currentPage=" + currentPage + "&pageSize=" + pageSize;
        if (nodeId != null) {
            param += "&data_type=" + nodeId;
        }
        if (keyword != null) {
            param += "&keyword=" + keyword;
        }
        if (need_content != null) {
            param += "&need_content=" + need_content;
        }
        let result = await axios.get("/api/v1/front/commonForm/getList?" + param);
        return result;
    },
    getCommonArticleDetail: async function (articleId) {
        let result = await axios.get("/api/v1/front/commonForm/detail?int_id=" + articleId);
        return result;
    },
    // 天气查询
    getWeather: async function (province, city, county, date) {
        return await axios.get('/api/v1/front/common/weather?county=' + county + '&province=' + province +
            '&city=' + city + '&date=' + date);
    },
    // 字典查询
    getDictByParentName: async function (parentName) {
        return axios({
            method: 'GET',
            url: "/api/v1/front/common/getDictByParentName",
            params: {
                parentName: parentName
            }
        });
    },
    // 首页日志 类型:1-h5portal、2-wxminiportal、3-webportal、4-webmanager、5-webdriver
    postLog: async function (type) {
        return axios({
            method: 'POST',
            url: "/api/v1/front/log/postLog",
            headers: {
                post: {
                    'Content-Type': 'application/json'
                }
            },
            data: JSON.stringify({
                type: type
            }),
        });
    }

}