/*
 * @Author: your name
 * @Date: 2021-08-19 13:51:38
 * @LastEditTime: 2021-08-23 16:37:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /szpwz_official_web/src/api/bigDataApi.js
 */
import axios from './axios'

let bigDataApi = {
    /**
     * 获取昨日客流总数、当日客流总数、实时客流量
     */
    getYesterdayTodayAndTimeData: async function () {
        let result = await axios({
            method: 'GET',
            url: "/api/v1/front/bigdata/yk/getYesterdayTodayAndTimeData",
            params: {
            }
        });
        return result;
    },
}



export default bigDataApi