
<template>
  <div class="boss">
    <div class="top">
      <div>
        <a href="https://www.mct.gov.cn/" class="link" target="_blank">
          <img :src="require('../assets/common/BottomSidebar/sy_082.jpg')">
          <p>中华人民共和国文化和旅游部</p>
        </a>
      </div>
      <div>
        <a href="http://wlt.jiangsu.gov.cn/" class="link" target="_blank">
          <img :src="require('../assets/common/BottomSidebar/sy_082.jpg')">
          <p>中华人民共和国文化和旅游部</p>
        </a>
      </div>
      <div>
        <a href="http://wglj.suzhou.gov.cn/" class="link" target="_blank">
          <img :src="require('../assets/common/BottomSidebar/sy_103.jpg')">
          <p>苏州市文化广电和旅游局</p>
        </a>
      </div>
      <div>
        <a href="http://visitsz.wglj.suzhou.com.cn/" class="link" target="_blank">
          <img :src="require('../assets/common/BottomSidebar/sy_031.jpg')">
          <p>苏州市旅游咨询中心</p>
        </a>
      </div>
      <div>
        <a href="http://visitsz.wglj.suzhou.com.cn/" class="link" target="_blank">
          <img :src="require('../assets/common/BottomSidebar/sy_031.jpg')">
          <p>吴江区旅游咨询中心</p>
        </a>
      </div>
      <div>
        <!-- <a href="http://visitsz.wglj.suzhou.com.cn/" class="link" target="_blank"> -->
          <img :src="require('../assets/common/BottomSidebar/sy_077.jpg')" style="width: 91px;height: 90px;">
          <p>江村旅游</p>
        <!-- </a> -->
      </div>
    </div>
    <div class="bottom">
      <div>
        <span><router-link :to="'/article/listDetail/186'" class="link" target="_blank">
            温馨提示</router-link></span>|
        <span><router-link :to="'/article/listDetail/206'" class="link" target="_blank">
            在线互动</router-link></span>|
        <span><router-link :to="'/article/listDetail/185'" class="link" target="_blank">
            救援电话</router-link></span>|
        <span><router-link :to="'/article/listDetail/233'" class="link" target="_blank">
            留言投诉</router-link></span>
      </div>
      <div>
        <span>
          <a href="https://www.ly.com/" class="link" target="_blank">同程旅游</a></span>|
        <span><a href="https://www.ctrip.com" class="link" target="_blank">携程网</a></span>|
        <span><a href="http://www.lvmama.com" class="link" target="_blank">驴妈妈</a></span>
      </div>
      <div>
        <span><router-link :to="'/article/listDetail/233'" class="link" target="_blank">
            关于我们</router-link></span>|
        <span><router-link :to="'/article/listDetail/185'" class="link" target="_blank">
            联系我们</router-link></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BottomSidebar',
  data() {
    return {
      urls: [],
    }
  },
  methods: {
    async initMenuData() {
      let result = await this.$apiUtil.getNodeList(44, 2)
      this.urls = result.resultList
    },
  },
  mounted() {
    this.initMenuData()
  },
  setup() {
    return {
      title: 'BottomSidebar',
    }
  },
}
</script>
<style scoped >
.boss {
  height: 100%;
  background: rgb(51, 51, 51);
}

.top {
  height: 50%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgb(146, 146, 146);
  color: rgb(255, 255, 255);
}

.top div {
  margin: 15px;
}

.bottom {
  height: 50%;
  color: rgb(255, 255, 255);
  overflow: hidden;
}

.bottom div {
  margin-top: 20px;
  margin-bottom: 20px;
}

.bottom span {
  margin: 20px;
}

.link {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
</style>
