<template xmlns:el-row="http://www.w3.org/1999/html">
  <div class="main-top">
    <div class="banner">
      <el-image :lazy="true" v-if="node.top_banner_img_uuid && node.top_banner_img_uuid.length > 0" class="top-banner"
        :src="baseApiURL + node.top_banner_img_uuid">
        <template v-slot:error>
          <img class="top-banner" loading="lazy" :src="require('../../assets/index/top6.webp')" />
        </template>
      </el-image>
      <!-- <el-image :lazy="true" v-else class="top-banner"
        :src="require('@/assets/common/article/banner_article.png')"></el-image> -->
      <span class="top-title">{{ node.node_name }}</span>
    </div>

    <el-row>
      <el-col :span="3"></el-col>
      <el-col :span="18">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ node.node_name }}</el-breadcrumb-item>
        </el-breadcrumb>

        <!-- 风物特产 -->
        <div id="specialty">
          <!--   标题   -->
          <div class="title">
            <span class="title_zh">{{ goods[0].name }}</span>
            <span class="title_en">{{ goods[0].name_en }}</span>
          </div>
          <div class="goods-list">
            <div class="goods-list-item" v-for="(item, index) in goods[0].list" :key="index">
              <el-image :lazy="true" class="item-cover" :src="item.mainImageUrl">
                <template v-slot:error>
                  <img :src="require('../../assets/index/default.png')
                    " />
                </template>
              </el-image>
              <span class="item-title">{{ item.title }}</span>
              <p class="item-desc">{{ item.sellPoint }}</p>
              <div class="buttom">
                <span class="item-price">
                  ¥ {{ (item.price).toFixed(2) }}
                </span>
                <button class="buy-button" @click="handleGoodsClick(item)">
                  立即购买
                </button>
              </div>
            </div>
          </div>
          <div class="show-more">
            <button @click="handleDetail(goods[0].tag_id)">查看更多</button>
          </div>
        </div>
        <!-- 餐饮美食 -->
        <div id="food">
          <!--   标题   -->
          <div class="title">
            <span class="title_zh">{{ goods[1].name }}</span>
            <span class="title_en">{{ goods[1].name_en }}</span>
          </div>
          <div class="goods-list">
            <div class="goods-list-item" v-for="(item, index) in goods[1].list" :key="index">
              <el-image :lazy="true" class="item-cover" :src="baseApiURL + item.font_img_id">
                <template v-slot:error>
                  <img :src="require('../../assets/index/default.png')
                    " />
                </template>
              </el-image>
              <div class="item-info">
                <div class="item-top">
                  <span class="item-title">{{ item.title }}</span>
                  <span class="item-price">
                    <!-- ¥ {{ (item.price).toFixed(2) }} -->
                  </span>
                </div>
                <p class="item-desc">{{ item.txt_content }}</p>

                <button class="buy-button" @click="handleGoodsClick(item)">
                  立即购买
                </button>
              </div>
            </div>
          </div>
          <div class="show-more">
            <button @click="handleDetail(goods[1].tag_id)">查看更多</button>
          </div>
        </div>

        <!-- 线路产品 -->
        <div id="line">
          <!--   标题   -->
          <div class="title">
            <span class="title_zh">{{ goods[3].name }}</span>
            <span class="title_en">{{ goods[3].name_en }}</span>
          </div>
          <div class="goods-list">
            <div class="goods-list-item" v-for="(item, index) in goods[3].list" :key="index">
              <el-image :lazy="true" class="item-cover" :src="item.mainImageUrl">
                <template v-slot:error>
                    <img :src="require('../../assets/index/default.png')
                      " />
                  </template>
              </el-image>
              <div class="item-info">
                <div class="item-top">
                  <span class="item-title">{{ item.title }}</span>
                </div>
                <p class="item-desc">
                  {{ item.sellPoint }}
                </p>
                <div class="buttom">
                  <span class="item-price">
                    ¥ {{ (item.price).toFixed(2) }}
                  </span>
                  <button class="buy-button" @click="handleGoodsClick(item)">
                    立即购买
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="show-more">
            <button @click="handleDetail(goods[3].tag_id)">查看更多</button>
          </div>
        </div>
        <div class="three">

          <!-- 酒店住宿 -->
          <div id="hotel" class="play" style="display: none;">
            <!--   标题   -->
            <div class="title">
              <span class="title_zh">{{ goods[2].name }}</span>
              <span class="title_en">{{ goods[2].name_en }}</span>
            </div>
            <div class="goods-list">
              <div class="goods-list-item" v-for="(item, index) in goods[2].list" :key="index">
                <el-image :lazy="true" class="item-cover" :src="baseApiURL + item.font_img_id">
                  <template v-slot:error>
                    <img :src="require('../../assets/index/default.png')
                      " />
                  </template>
                </el-image>
                <div class="item-info">
                  <div class="item-top">
                    <span class="item-title">{{ item.title }}</span>
                  </div>
                  <p class="item-desc">
                    {{ item.txt_content }}
                  </p>
                  <div class="buttom">
                    <span class="item-price">
                      <!-- ¥ {{ (item.price).toFixed(2) }} -->
                    </span>
                    <button class="buy-button" @click="handleGoodsClick(item)">
                      立即购买
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="show-more">
              <button @click="handleDetail(goods[2].tag_id)">查看更多</button>
            </div>
          </div>
          <!-- 游乐项目 -->
          <div id="play" class="play" style="display: none;">
            <!--   标题   -->
            <div class="title">
              <span class="title_zh">{{ goods[4].name }}</span>
              <span class="title_en">{{ goods[4].name_en }}</span>
            </div>
            <div class="goods-list">
              <div class="goods-list-item" v-for="(item, index) in goods[4].list" :key="index">
                <el-image :lazy="true" class="item-cover" :src="baseApiURL + item.font_img_id">
                  <template v-slot:error>
                    <img :src="require('../../assets/index/default.png')
                      " />
                  </template>
                </el-image>
                <div class="item-info">
                  <div class="item-top">
                    <span class="item-title">{{ item.title }}</span>
                  </div>
                  <p class="item-desc">
                    {{ item.txt_content }}
                  </p>
                  <div class="buttom">
                    <span class="item-price">
                      <!-- ¥ {{ (item.price).toFixed(2) }} -->
                    </span>
                    <button class="buy-button" @click="handleGoodsClick(item)">
                      立即购买
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="show-more">
              <button @click="handleDetail(goods[4].tag_id)">查看更多</button>
            </div>
          </div>
          <!-- 导游预订 -->
          <div id="guide" class="play" style="margin: 0 auto;display: none;">
            <!--   标题   -->
            <div class="title">
              <span class="title_zh">{{ goods[5].name }}</span>
              <span class="title_en">{{ goods[5].name_en }}</span>
            </div>
            <div class="goods-list">
              <div v-html="goodsDetail.content" class="content"></div>
              <!-- <div class="goods-list-item" v-for="(item, index) in goods[5].list" :key="index">
                <el-image :lazy="true" class="item-cover" :src="baseApiURL + item.font_img_id">
                  <template v-slot:error>
                    <img :src="require('../../assets/index/default.png')
                      " />
                  </template>
                </el-image>
                <div class="item-info">
                  <div class="item-top">
                    <span class="item-title">{{ item.title }}</span>
                  </div>
                  <p class="item-desc">
                    {{ item.txt_content }}
                  </p>
                  <div class="buttom">
                    <span class="item-price">
                    </span>
                    <button class="buy-button" @click="handleGoodsClick(item)">
                      立即预订
                    </button>
                  </div>
                </div>
              </div> -->
            </div>
            <!-- <div class="show-more">
              <button @click="handleDetail(goods[5].tag_id)">查看更多</button>
            </div> -->
          </div>
        </div>
      </el-col>
      <el-col :span="3"></el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'onlineBookIndex',
  data() {
    return {
      // 显示面包屑导航
      baseApiURL: this.$baseApiURL + '/api/v1/file/download?uuid=',

      node: {
        parent_id: '',
        parent_node_name: '',
        parent_previewURL: '',
        // 在线预定 id
        int_id: '159',
        node_name: '',
        node_name_en: '',
        description: '',
        previewURL: '',
        top_banner_img_uuid: '',
        ylxmlist: []
      },
      goods: [
        {
          name: '风物特产',
          is_select: false,
          img_url: require('@/assets/index/icon/shouye_shangpinyuding.svg'),
          img_url_select: require('@/assets/index/icon/shouye_shangpinyuding-xuanzhong.svg'),
          url: 'https://shop97220858.youzan.com/v2/showcase/tag?alias=3epl5fpmfcjk2',
          tag_id: '228',
        },
        {
          name: '餐饮美食',
          is_select: false,
          img_url: require('@/assets/index/icon/shouye_canyinyuding.svg'),
          img_url_select: require('@/assets/index/icon/shouye_canyinyuding-xuanzhong.svg'),
          url: 'https://shop97220858.youzan.com/v2/showcase/tag?alias=1ylmlgubbwh5e',
          tag_id: '222',
        },
        {
          name: '酒店住宿',
          is_select: false,
          img_url: require('@/assets/index/icon/shouye_jiudianyuding.svg'),
          img_url_select: require('@/assets/index/icon/shouye_jiudianyuding-xuanzhong.svg'),
          url: 'https://shop97220858.youzan.com/v2/showcase/tag?alias=1yah8ww5cgj5u',
          tag_id: '223',
        },
        {
          name: '线路产品',
          is_select: false,
          img_url: require('@/assets/index/icon/shouye_menpiaoyuding.svg'),
          img_url_select: require('@/assets/index/icon/shouye_menpiaoyuding-xuanzhong.svg'),
          url: 'https://shop97220858.youzan.com/v2/showcase/tag?alias=3f0ornkzfttki',
          tag_id: '221',
        },
        {
          name: '游乐项目',
          is_select: false,
          img_url: require('@/assets/index/icon/shouye_youlexiangmu.svg'),
          img_url_select: require('@/assets/index/icon/shouye_youlexiangmu-xuanzhong.svg'),
          url: 'https://shop97220858.youzan.com/v2/showcase/tag?alias=360r3iq64insy',
          tag_id: '227',
        },
        {
          name: '导游预订',
          is_select: false,
          img_url: require('@/assets/index/icon/shouye_daoyoufuwu.svg'),
          img_url_select: require('@/assets/index/icon/shouye_daoyoufuwu-xuanzhong.svg'),
          url: 'https://shop97220858.youzan.com/v2/showcase/tag?alias=274cb8e2okhiq',
          tag_id: '226',
        },
      ],
      goodsDetail:"",
    }
  },
  methods: {
    async getNodeAndParent() {
      let result = await this.$apiUtil.getNodeAndParent(this.node.int_id)
      if (result.returnCode === 1 && result.result) {
        this.node = result.result
      } else {
        this.node.node_name = '栏目不存在'
        this.$message.error(result.description)
      }
    },
    handleDetail(type) {
      //第一步定义routeUrl
      let routeUrl = this.$router.resolve({
        path: '/onlineBooking/goodsSearch/' + type,
      })
      // 第二步：新标签页打开
      window.open(routeUrl.href, '_blank')
    },
    handleGoodsClick(goods) {
        window.open(goods.webDetailUrl, '_blank')
      // if (
      //     goods.detail_url &&
      //     !goods.detail_url.startsWith('https://h5.youzan.com')
      // ) {
      //   window.open(goods.webDetailUrl, '_blank')
      // } else {
      //   window.open(
      //       'https://detail.youzan.com/show/goods?alias=' + goods.alias,
      //       '_blank'
      //   )
      // }
    },
    // 电商对应关系
    async getGoodsCategories(){
      let res = await this.$apiUtil.yzGoodsApi.getGoodsCategories()  
      // {228 "风物特产"
      //  222 "餐饮美食"
      //  223 "酒店住宿"
      //  221 "线路产品"
      //  227 "游乐项目"
      //  226 "导游预订"}
      res.resultList.forEach((item) => {
        if (item.node_id == 228) {
          this.goods[0].tag_id = item.category_id
        // }else if (item.node_id == 222) {
        //   this.goods[1].tag_id = item.category_id
        // }else if (item.node_id == 223) {
        //   this.goods[2].tag_id = item.category_id
        }else if (item.node_id == 221) {
          this.goods[3].tag_id = item.category_id
        // }else if (item.node_id == 227) {
        //   this.goods[4].tag_id = item.category_id
        // }else if (item.node_id == 226){
        //   this.goods[5].tag_id = item.category_id
        }
      })
      console.log(this.goods,44444444444);
    },
    // 获取电商
    async getGoodsList(currentPage, pageSize, tag_id, keyword, index) {
      let result = await this.$apiUtil.yzGoodsApi.getGoodsList(
          currentPage,
          pageSize,
          this.goods[index].tag_id,
          keyword
      )
      if (result.returnCode === 1 && result.result) {
        this.goods[index].list = result.result.list
      }
    },
    // 获取导游预订内容管理发布详情
    async getPageDetailList() {
      let result = await this.$apiUtil.getCommonArticleDetail(151) //正式环境导游预定
      if (result.returnCode === 1 && result.result) {
        this.goodsDetail = result.result
      }
    },
    //模拟锚点跳转
    goAnchor(selector) {
      //参数selector是id选择器（#anchor14）
      document.querySelector(selector).scrollIntoView({
        behavior: 'smooth',
      })
    },
    init() {
      this.getNodeAndParent()
      this.getGoodsCategories().then(()=>{
        this.getGoodsList(1, 6, '', '', 0)
        // this.getPageList(1, 1, '', '', 1)
        // this.getPageList(1, 1, '', '', 2)
        // this.getGoodsList(1, 1, '', '', 3)
        // this.getPageList(1, 1, '', '', 4)
        // this.getPageDetailList()

      })
    },
  },
  mounted() {
    if (this.$route.hash) {
      this.goAnchor(this.$route.hash)
    }
    this.init()
  },
  watch: {
    $route() {
      if (this.$route.hash) {
        this.goAnchor(this.$route.hash)
      }
    },
  },
}
</script>

<style scoped lang="less">
.el-row {
  background: url('../../assets/onlineBooking/bg.png') repeat-y;
  background-size: 1920px auto;
}

.main-top {
  .banner {
    min-height: 560px;
    height: 560px;
    position: relative;

    .top-banner {
      width: 100%;
      height: 560px;
    }

    .top-title {
      display: none;
      position: absolute;
      top: 165px;
      left: 78%;
      writing-mode: tb;
      width: 54px;
      height: 250px;
      font-size: 60px;
      font-family: MaShanZhengMaoBiKaiTi, serif !important;
      font-weight: 400;
      color: #ffffff;
      z-index: 10;
    }
  }

  .el-breadcrumb {
    font-size: 18px;
    margin-top: 12px;
    margin-bottom: 20px;
    width: 200px;
    line-height: 50px;
    position: absolute;
    top: -140px;
    left: 400px;
  }

  ::v-deep .el-breadcrumb__inner {
    color: white;
  }

  ::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner {
    color: white;
    font-size: 40px;
    font-weight: 500;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    height: 70px;
    background: url('../../assets/common/article/banner_article_node_bg.png') no-repeat center;
    background-size: 470px 84px;
    margin-bottom: 20px;
    margin-top: 80px;

    .title_zh {
      font-size: 36px;
      font-weight: 400;
      color: #1c425e;
    }

    .title_en {
      font-size: 16px;
      font-weight: 400;
      color: #dbdbdb;
    }
  }

  .show-more {
    button {
      width: 145px;
      height: 45px;
      margin-top: 40px;
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      border: none;
      background: url('../../assets/onlineBooking/bg_button.png') no-repeat;
      background-size: 145px 45px;
      cursor: pointer;
    }

    button:hover {
      color: #333333;
    }
  }

  #specialty {
    .goods-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      // height: 550px;
      min-height: 550px;

      .goods-list-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 40px;
        width: 440px;
        height: 550px;
        background: #ffffff;
        box-shadow: 0px 0px 18px 0px rgba(1, 47, 79, 0.23);
        border-radius: 10px;

        .item-cover {
          width: 440px;
          height: 330px;
          border-radius: 10px;
        }

        .item-title {
          height: 60px;
          font-size: 30px;
          font-weight: 500;
          color: #333333;
          line-height: 30px;
          margin: 27px 0 20px 20px;
          text-align: left;

          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }

        .item-desc {
          margin: 0px 20px;
          height: 50px;
          font-size: 18px;
          font-weight: 400;
          color: #999999;
          line-height: 25px;
          text-align: left;
          text-indent: 2em;

          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }

        .buttom {
          width: 400px;

          margin: 30px 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .item-price {
            height: 22px;
            font-size: 22px;
            font-weight: 500;
            color: #ff5a00;
            line-height: 25px;
          }

          .buy-button {
            width: 140px;
            height: 42px;
            border-radius: 10px;
            background: url('../../assets/onlineBooking/bg_button_2.png') no-repeat;
            background-size: 140px 42px;

            border-radius: 2px;
            font-size: 16px;
            font-weight: 400;
            color: #ffffff;
            line-height: 37px;
            cursor: pointer;
          }
        }
      }
    }
  }

  #food {
    display: none;

    .goods-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 100px;
      height: 700px;
      min-height: 700px;

      .goods-list-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        width: 388px;
        height: 582px;
        background: #1c425e;
        box-shadow: 0px 8px 38px 0px rgba(4, 0, 0, 0.15);
        border-radius: 10px;

        .item-cover {
          width: 388px;
          height: 291px;
          border-radius: 10px;
        }

        .item-info {
          width: 348px;
          height: 251px;
          padding: 20px;
          text-align: left;

          .item-top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .item-title {
              width: 250px;
              height: 60px;
              font-size: 30px;
              font-weight: 500;
              color: #ffffff;
              line-height: 60px;
              text-align: left;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
            }

            .item-price {
              height: 60px;
              font-size: 22px;
              font-weight: 500;
              color: #ffffff;
              line-height: 60px;
            }
          }
        }

        .item-desc {
          margin: 30px 0px;
          height: 81px;
          font-size: 19px;
          font-weight: 400;
          color: #ffffff;
          line-height: 27px;
          text-align: left;
          text-indent: 2em;

          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }

        .buy-button {
          margin-top: 10px;
          width: 111px;
          height: 37px;
          border: 1px solid #ff5a00;
          background-color: #1c425e;
          border-radius: 2px;
          font-size: 16px;
          font-weight: 500;
          color: #ff5a00;
          line-height: 37px;
          cursor: pointer;
        }
      }

      .goods-list-item:nth-child(2) {
        flex-direction: column-reverse;
      }
    }
  }

  .hotel {
    .goods-list {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      // padding: 0 100px;
      height: 564px;
      min-height: 564px;
      flex-wrap: wrap;

      .goods-list-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        width: 704px;
        height: 264px;
        box-shadow: 0px 8px 38px 0px rgba(4, 0, 0, 0.15);
        border-radius: 10px;

        .item-cover {
          width: 352px;
          height: 264px;
          border-radius: 10px;
        }

        .item-info {
          width: 352px;
          height: 264px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background: url('../../assets/onlineBooking/bg_hotel.png') no-repeat;
          background-size: 352px 264px;
          background-color: #ffffff;

          .item-top {
            .item-title {
              max-width: 300px;
              height: 22px;
              font-size: 22px;
              font-weight: 400;
              color: #333333;
              line-height: 22px;
              text-align: left;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
            }
          }
        }

        .item-desc {
          text-align: left;
          max-width: 300px;
          height: 50px;
          font-size: 18px;
          font-weight: 400;
          color: #666666;
          line-height: 25px;
          text-align: left;
          text-indent: 2em;

          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }

        .item-price {
          display: block;
          height: 60px;
          font-size: 28px;
          font-weight: 500;
          color: #ff5a00;
          line-height: 60px;
        }

        .buy-button {
          width: 140px;
          height: 42px;
          border-radius: 10px;
          background: url('../../assets/onlineBooking/bg_button_2.png') no-repeat;
          background-size: 140px 42px;

          border-radius: 2px;
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
          line-height: 37px;
          cursor: pointer;
        }
      }

      .goods-list-item:nth-child(3),
      .goods-list-item:nth-child(4) {
        flex-direction: row-reverse;
      }
    }
  }

  #line {
    display: none;

    .goods-list {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 550px;
      min-height: 550px;

      .goods-list-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        width: 440px;
        height: 550px;
        box-shadow: 0px 0px 18px 0px rgba(1, 47, 79, 0.23);
        border-radius: 10px;

        .item-cover {
          width: 440px;
          height: 330px;
          border-radius: 10px;
        }

        .item-info {
          width: 400px;
          height: 200px;
          padding: 20px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          background-color: #ffffff;

          .item-top {
            .item-title {
              height: 60px;
              font-size: 30px;
              font-weight: 400;
              color: #333333;
              line-height: 60px;
              text-align: left;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
            }
          }
        }

        .item-desc {
          text-align: left;
          height: 100px;
          font-size: 16px;
          font-weight: 400;
          color: #666666;
          line-height: 25px;
          text-align: left;
          text-indent: 2em;

          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }

        .buttom {
          width: 400px;

          display: flex;
          align-items: center;
          justify-content: space-between;

          .item-price {
            display: block;
            height: 60px;
            font-size: 25px;
            font-weight: 500;
            color: #ff5a00;
            line-height: 60px;
          }

          .buy-button {
            width: 140px;
            height: 40px;
            border-radius: 10px;
            background: url('../../assets/onlineBooking/bg_button_2.png') no-repeat;
            background-size: 140px 40px;

            border-radius: 2px;
            font-size: 16px;
            font-weight: 400;
            color: #ffffff;
            line-height: 37px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .play {
    .goods-list {
      display: flex;
      align-items: center;
      justify-content: center;

      .goods-list-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        width: 388px;
        height: 582px;
        background: #1c425e;
        box-shadow: 0px 8px 38px 0px rgba(4, 0, 0, 0.15);
        border-radius: 10px;

        .item-cover {
          width: 388px;
          height: 291px;
          border-radius: 10px;
        }

        .item-info {
          width: 348px;
          height: 251px;
          padding: 20px;
          text-align: left;

          .item-top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .item-title {
              height: 60px;
              font-size: 30px;
              font-weight: 500;
              color: #ffffff;
              line-height: 60px;
              text-align: left;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
            }

            .item-price {
              height: 60px;
              font-size: 22px;
              font-weight: 500;
              color: #ffffff;
              line-height: 60px;
            }
          }
        }

        .item-desc {
          margin: 30px 0px;
          height: 81px;
          font-size: 18px;
          font-weight: 400;
          color: #ffffff;
          line-height: 27px;
          text-align: left;
          text-indent: 2em;

          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }

        .buttom {
          width: 345px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .item-price {
            display: block;
            height: 60px;
            font-size: 28px;
            font-weight: 500;
            color: #ff5a00;
            line-height: 60px;
          }

          .buy-button {
            width: 111px;
            height: 37px;
            border: 1px solid #ff5a00;
            background-color: #1c425e;
            border-radius: 2px;
            font-size: 16px;
            font-weight: 500;
            color: #ff5a00;
            line-height: 37px;
            cursor: pointer;
          }
        }
      }

      .goods-list-item:nth-child(2) {
        flex-direction: column-reverse;
      }
    }
  }

  .guide {
    .goods-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 0 100px;
      height: 528px;
      min-height: 528px;

      .goods-list-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        width: 1410px;
        height: 528px;
        box-shadow: 0px 8px 38px 0px rgba(4, 0, 0, 0.15);
        border-radius: 10px;

        .item-cover {
          width: 705px;
          height: 528px;
          border-radius: 10px;
        }

        .item-info {
          width: 705px;
          height: 528px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background: url('../../assets/onlineBooking/bg_hotel.png') no-repeat;
          background-size: 705px 528px;
          background-color: #ffffff;

          .item-top {
            .item-title {
              max-width: 605px;
              height: 60px;
              font-size: 22px;
              font-weight: 400;
              color: #333333;
              line-height: 60px;
              text-align: left;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
            }
          }
        }

        .item-desc {
          text-align: left;
          width: 640px;
          max-width: 640px;
          height: 210px;
          font-size: 18px;
          font-weight: 400;
          color: #666666;
          line-height: 30px;
          text-align: left;
          text-indent: 2em;

          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 7;
        }

        .item-price {
          display: block;
          height: 60px;
          font-size: 28px;
          font-weight: 500;
          color: #ff5a00;
          line-height: 60px;
        }

        .buy-button {
          width: 140px;
          height: 42px;
          border-radius: 4px;
          background: url('../../assets/onlineBooking/bg_button_2.png') no-repeat;
          background-size: 140px 42px;
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
          line-height: 37px;
          cursor: pointer;
        }
      }
    }

    .show-more {
      margin-bottom: 100px;
    }
  }
}
.content {
    min-height: 400px;
    // 设置为默认值，屏蔽继承父级的center
    text-align: initial;
    color: #333333;
    line-height: 24px;
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, sans-serif !important;
  }
.three {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;

  padding-bottom: 100px;
}
</style>
