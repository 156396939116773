<template>
  <mainTop></mainTop>

  <div class="article-detail">
    <el-row>
      <el-col :span="4"></el-col>

      <el-col :span="16">
        <div v-if="article.title !== ''">
          <!--   标题   -->
          <div class="title">
            <span class="title_zh">{{ article.title }}</span>
          </div>
          <!--   时间   -->
          <div class="time">
            <span>发布时间：2023-08-17</span>
          </div>



          <!--   内容   -->
          <!-- <div class="qrcode-box2">
            <el-image class="qrcode-img2" :src="qrCode"></el-image>
          </div> -->
          <div class="content">
            <el-image :src="baseApiURL + article.file_ids"></el-image>
          </div>
        </div>

        <el-empty v-else description="文章不存在或已删除"></el-empty>
      </el-col>
      <el-col :span="4">
        <!-- 悬浮二维码-->
        <!--        <div class="qrcode-box" v-show="orderQRShow" @click="handleShowQR">-->
        <!--          <el-image class="qrcode-img" :src="qrCode"></el-image>-->
        <!--        </div>-->
        <!--        <div class="direction-text" @click="handleShowQR">-->
        <!--          <span>扫码导览</span>-->
        <!--          <el-image-->
        <!--              class="arrow-up"-->
        <!--              :class="{ transform: orderQRShow }"-->
        <!--              :src="require('@/assets/index/bg_windows_yycg_arrow.png')">-->
        <!--          </el-image>-->
        <!--        </div>-->
      </el-col>
    </el-row>
  </div>
</template>

<script>
// 全景导览

import mainTop from '@/components/article/mainTop'

export default {
  name: 'detail',
  components: {
    mainTop,
  },
  data() {
    return {
      baseApiURL: this.$baseApiURL + '/api/v1/file/download?uuid=',
      articleId: '',
      article: {
        int_id: 1,
        title: '全景导览',
        create_time: '2021-08-17',
        time_stamp: '2021-08-17',
        content: '',
        txt_content: '',
      },
      srcList: [
        'https://www.mmjcjq.com/qidu_zhglpt-ws/api/v1/file/download?uuid=e087a804-103f-46d1-af0f-ad66f35d3778',
      ],
      qrCode:'https://www.mmjcjq.com/qidu_zhglpt-ws/api/v1/file/download?uuid=2e76037a-92c7-43ca-9047-efa63ce06b9d',
      orderQRShow: false,
    }
  },
  methods: {
    async getDetailById() {
      let result = await this.$apiUtil.getCommonArticlePage(
        this.articleId,
        1,
        1,
        '',
        1
      )
      if (
        result.returnCode === 1 &&
        result.result &&
        result.result.list.length === 1
      ) {
        this.article = result.result.list[0]
      }
    },
    handleShowQR() {
      this.orderQRShow = !this.orderQRShow
    },
    init() {
        this.articleId = this.$route.params.nodeId;
        this.getDetailById();
    },
  },

  watch: {
    $route() {
        this.init();
    },
  },
  mounted() {
    this.init();
  },
}
</script>
<style scoped lang="less">
.article-detail {
  margin-bottom: 200px;


  .title {
    .title_zh {
      font-size: 36px;
      font-weight: 400;
      color: #1c425e;
    }
  }

  .time {
    font-size: 16px;
    font-weight: 400;
    color: #999999;
    line-height: 16px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 50px;
  }

  .qrcode-box2 {
    text-align: right;
  }

  .content {
    min-height: 400px;
    // 设置为默认值，屏蔽继承父级的center
    text-align: initial;
    color: #333333;
    line-height: 24px;
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, sans-serif !important;

    .el-image {
      width: 100%;
      max-width: 100%;
    }
  }

  .qrcode-box {
    position: absolute;
    display: block;
    top: -205px;
    right: 25px;

    .qrcode-img {
      width: 187px;
      height: 219px;
    }
  }

  .direction-text {
    position: absolute;
    top: 0px;
    right: 10px;
    width: 212px;
    height: 83px;
    background: url('../../assets/index/bg_window_2.png') no-repeat;
    background-size: 212px 83px;
    text-align: center;
    cursor: pointer;

    span {
      width: 72px;
      height: 18px;
      line-height: 83px;
      font-size: 18px;
      font-family: SourceHanSansCN-Regular, serif;
      font-weight: 400;
      color: #ffffff;
      text-shadow: 0px 6px 10px rgba(34, 102, 149, 0.59);
    }

    .arrow-up {
      width: 16px;
      height: 16px;
      margin-top: 32px;
      margin-left: 10px;
    }

    .transform {
      transform: rotate(180deg);
    }
  }
}
</style>
