/*
 * @Author: your name
 * @Date: 2021-08-27 16:08:11
 * @LastEditTime: 2021-08-29 15:09:45
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /szpwz_official_web/src/store/index.js
 */
import { createStore } from 'vuex'

const store = createStore({
    state() {
        return {
            IsLogin: false,
            access_token: null,
            userInfo: {
                int_id: null,
                nick_name: '',
                head_url: '',
                appid: '',
                open_id: null,
                union_id: null,
                phone_num: '',
                gender: '',
                id_card_num: null,
                country: '',
                province: '',
                city: ''
            },
            // 重定向地址，登录成功后返回原始页面
            redirect_url: '/'
        }
    },
    mutations: {
        login(state, access_token) {
            state.IsLogin = true;
            state.access_token = access_token;
        },
        userInfo(state, userInfo) {
            state.userInfo = userInfo;
        },
        redirect_url(state, redirect_url) {
            state.redirect_url = redirect_url;
        },
        logout(state) {
            state.IsLogin = false;
            state.access_token = null;
            state.userInfo = {
                int_id: null,
                nick_name: '',
                head_url: '',
                appid: '',
                open_id: null,
                union_id: null,
                phone_num: '',
                id_card_num: null,
                country: '',
                province: '',
                city: ''
            };
        },
    }
})

export default store;