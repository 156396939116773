<!--
 * @Author: your name
 * @Date: 2021-08-28 17:46:28
 * @LastEditTime: 2021-08-30 14:47:06
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /szpwz_official_web/src/views/user/center/myTicketOrder.vue
-->

<template>
  <div class="page" v-if="page.total > 0" v-show="!showDetail">
    <div class="list">
      <div class="list-item" v-for="(item, index) in page.list" :key="index">
        <div class="list-item-top">
          <div class="title">订单编号：{{ item.tid }}</div>
          <div class="state">
            <span>{{ item.status_str }}</span>
          </div>
        </div>
        <div class="list-item-bottom">
          <div class="title">下单时间：{{ item.order_created }}</div>
          <div class="num">实际支付：{{ item.real_payment + '元' }}</div>
          <div class="phone">联系手机：{{ item.buyer_phone }}</div>
          <div class="a">
            <a @click="handelDetail(item)">查看详情</a>
          </div>
        </div>
      </div>
    </div>

    <el-pagination
      class="pagination"
      @current-change="handleCurrentChange"
      background
      :current-page="page.currentPage"
      :page-size="page.pageSize"
      prev-text="上一页"
      next-text="下一页"
      layout="prev, pager, next, jumper, total"
      :total="page.total"
    >
    </el-pagination>
  </div>
  <el-empty v-else description="您还没有商品订单"></el-empty>

  <div class="detail" v-if="showDetail">
    <div class="back">
      <a @click="goBackToList()">返回列表</a>
    </div>
    <div class="base-info">
      <div><span>订单编号：</span>{{ detail.tid }}</div>
      <div><span>联系手机：</span>{{ detail.buyer_phone }}</div>
      <div><span>下单时间：</span>{{ detail.order_created }}</div>
      <div>
        <span>物流类型：</span
        >{{ getDictValue('express_type', detail.express_type) }}
      </div>
      <div><span>实际支付：</span>{{ detail.real_payment + '元' }}</div>
      <div><span>订单状态：</span>{{ detail.status_str }}</div>
    </div>
    <div class="tourist-list">
      <div class="list-item">商品信息：</div>
      <div
        class="list-item"
        v-for="(item, index) in full_order_info.orders"
        :key="index"
      >
        <div>
          <span>{{ index + 1 + '、' }}</span>
          <el-image class="goods" :src="item.picPath"></el-image>
          <el-link
            class="data"
            :href="item.goodsSnapshot"
            type="info"
            target="_blank"
          >
            {{ item.title }}</el-link
          >
          <span class="data"> {{ item.price + '元' }}</span>
          <span class=""> {{ ' * ' + item.num }}</span>
        </div>
        <div>
          买家信息：<span>{{ formatBuyerMessages(item.buyerMessages) }}</span>
        </div>
      </div>
    </div>
    <div class="desc">
      <span>实际支付：</span>
      {{ detail.real_payment + '元' }}
    </div>
  </div>
</template>
<script>
import yzOrderDict from '@/store/yzOrderDict.js'

export default {
  name: 'myGoodsOrder',
  data() {
    return {
      page: {
        currentPage: 1,
        pageSize: 5,
        total: 0,
        list: [],
      },

      showDetail: false,
      detail: {
        int_id: 0,
        time_stamp: '',
        create_time: '',
        node_kdt_id: null,
        buyer_id: null,
        buyer_phone: '',
        tid: '',
        outer_user_id: null,
        order_created: '',
        success_time: null,
        update_time: '',
        status: '',
        type: 0,
        express_type: 0,
        close_type: 0,
        status_str: '',
        refund_state: 0,
        expired_time: '',
        real_payment: '0.00',
        full_order_info: '',
      },
      full_order_info: {},
    }
  },
  methods: {
    getDictValue: function (dict_name, key) {
      return yzOrderDict[dict_name].find((dict) => dict.key == key).value
    },
    handleCurrentChange(val) {
      this.page.currentPage = val
      this.getPageList()
    },
    async getPageList() {
      let result = await this.$apiUtil.yzGoodsApi.getYzOrderListForVisitor(
        this.page.currentPage,
        this.page.pageSize,
        ''
      )
      if (result.returnCode === 1) {
        this.page.list = result.result.list
        this.page.total = result.result.total
        this.page.currentPage = result.result.pageNum
      }
    },
    async handelDetail(item) {
      this.detail = item
      this.full_order_info = JSON.parse(item.full_order_info)
      this.showDetail = true
    },
    goBackToList() {
      this.showDetail = false
    },
    formatBuyerMessages: function (buyerMessages) {
      let result = ''
      if (buyerMessages) {
        const msg = JSON.parse(buyerMessages)
        Object.keys(msg).map((key) => {
          result += key + ':' + msg[key] + ';'
        })
      }
      if (result === '') {
        result = '无'
      }
      return result
    },
  },
  mounted() {
    this.getPageList()
  },
}
</script>
<style lang="less" scoped>
.page {
  .list {
    min-height: 600px;

    .list-item {
      border: 1px solid #fdd78b;
      margin: 10px 0;

      .list-item-top {
        height: 40px;
        background: #fdf8ee;
        padding: 0 20px;

        font-size: 16px;
        font-weight: 400;
        color: #fdc75a;
        line-height: 24px;

        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .list-item-bottom {
        padding: 30px 20px 20px 20px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        div {
          text-align: left;
        }

        .a {
          text-align: right;

          a {
            cursor: pointer;
            padding: 5px;

            font-size: 14px;
            font-weight: 400;
            color: #ff7800;
            line-height: 24px;
            background: none;
            border: none;
          }
        }
      }
    }
  }

  :deep(.el-pagination) {
    margin-top: 40px !important;

    .btn-prev,
    .btn-next,
    .el-pagination__jump,
    .el-pagination__total {
      border: 1px solid #fdd78b !important;
    }

    .el-pager {
      .active {
        background-color: #fdc75a !important;
      }

      .number {
        border: 1px solid #fdd78b !important;
      }
    }
  }
}

.detail {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 49px;
  text-align: left;

  .base-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    text-align: left;

    div {
      width: 40%;
    }
  }

  .tourist-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 40px;
    text-align: left;

    .list-item {
      width: 100%;
      border-bottom: 1px solid rgba(102, 102, 102, 0.5);
      margin-bottom: 20px;

      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .goods {
          width: 150px;
        }

        .data {
          font-size: 16px;
          width: 150px;
        }
      }
    }
  }

  .desc {
    margin-top: 40px;
  }

  .back {
    a {
      background-color: #fdf8ee;
      border: none;
      color: #ff7800;
      cursor: pointer;
      padding: 5px;
    }
  }
}
</style>