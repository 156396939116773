<template>
  <div class="header">
    <div class="left">
      <div class="weather">
        <img src="../assets/common/TopSidebar/sy_06.jpg" class="weather_img">
        <p>
          {{
            weather.weather_type +
            ' ' +
            weather.weather_wendu_low +
            '~' +
            weather.weather_wendu_high
          }}
        </p>
        <p class="weather_quaily">{{ weather.weather_quaily }}</p>
      </div>
      <div class="center">
        <div>实时客流量：<span>{{ rightWindows.data.realTimeVisitorNum }}</span>人</div>
        <div>瞬时承载量：<span>{{ rightWindows.data.ChangGui.millMaxAllowVisitorNum }}</span>人</div>
        <div>景区承载量：<span>{{ rightWindows.data.ChangGui.dayMaxAllowVisitorNum }}</span>人</div>
<!--        <div>剩余停车位：<span>{{ rightWindows.data.realTimeRestParkingNum }}</span>个</div>-->
      </div>
    </div>

    <div class="right">
      <div class="div-search">
        <div @mouseenter="handleHideSearch" @mouseleave="handleHideSearch">
          <el-input class="input-search" placeholder="请输入关键词" @change="handleSearch" v-model="searchKeyWord"
            v-if="showSearch">
          </el-input>
          <el-image :src="require('../assets/common/TopSidebar/sy_03.jpg')" class="search_img"></el-image>
        </div>
      </div>

      <div class="language">
        <img src="../assets/common/TopSidebar/sy_18.jpg" class="language_img">
        <el-dropdown class="my-el-dropdown" @command="handleCommandForLang">
          <span class="el-dropdown-link">
            语言<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item class="my-el-dropdown-item" command="/">
                <img src="../assets/common/TopSidebar/sy_09.jpg">
              </el-dropdown-item>
              <el-dropdown-item class="my-el-dropdown-item" command="/en">
                <img src="../assets/common/TopSidebar/sy_11.jpg">
              </el-dropdown-item>
              <el-dropdown-item class="my-el-dropdown-item" command="/kr">
                <img src="../assets/common/TopSidebar/sy_15.jpg">
              </el-dropdown-item>
              <!-- <el-dropdown-item class="my-el-dropdown-item" disabled>
                <img src="../assets/common/TopSidebar/sy_13.jpg">
              </el-dropdown-item> -->
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>

      <div class="login-div">
        <el-button v-if="!$store.state.IsLogin" type="text" class="login" @click="handleLogin">登录
        </el-button>
        <div class="user-info" v-else>
          <el-image class="head-img" :src="$store.state.userInfo.head_url" @click="handlePersonalCenter()">
            <template v-slot:error>
              <img @click="handlePersonalCenter()" :src="require('@/assets/user/default_head_img.png')" />
            </template>
          </el-image>

          <el-image class="logout" @click="handleLogout()" :src="require('@/assets/user/logout.svg')">
          </el-image>
        </div>
      </div>
    </div>
  </div>
  <div class="menu">
    <!--  下拉菜单区域  -->
    <el-menu class="el-menu-demo el-col-24" mode="horizontal" background-color="white" text-color="black"
      active-text-color="rgb(22,144,155)" :default-active="defaultActive" menu-trigger="hover">
      <el-menu-item class="menu_logo">
        <router-link :to="'/'" class="link">
          <img src="../assets/common/TopSidebar/sy_28.jpg" class="menu_img"></router-link>
      </el-menu-item>
      <template v-for="first in topMenuList">
        <template v-if="!first.hasOwnProperty('children')">
          <el-menu-item class="my-el-menu-item" :key="first.id.toString()" :index="first.previewURL"
            @click="handleSubMenuClick(first.previewURL)">
            {{ first.name }}
          </el-menu-item>
        </template>
        <template v-else>
          <el-sub-menu :key="first.id.toString()" :index="first.previewURL">
            <template #title>{{ first.name }}</template>
            <el-menu-item class="my-el-menu-item" v-for="(second, index_2) in first.children" :key="index_2"
              :index="second.previewURL" @click="handleSubMenuClick(second.previewURL)" v-show="second.id != 235">
              {{ second.name }}
            </el-menu-item>
          </el-sub-menu>
        </template>
      </template>
    </el-menu>
  </div>
</template>
<script>
export default {
name: 'TopSidebar',
data(){
return{
 defaultActive: '/',
      searchKeyWord: '',
      showSearch: false,
      topMenuList: [
        {
          id: 38,
          parentId: 1,
          name: '官网',
          orderIndex: 1,
          previewURL: '',
          children: [],
        },
      ],
 weather: {
        int_id: 22,
        time_stamp: '2021-08-29 09:00:00',
        create_time: '2021-08-29 00:00:00',
        weather_province: '江苏',
        weather_city: '苏州',
        weather_county: '吴江',
        weather_type: '晴',
        weather_type_short: '云',
        weather_fx: '南风',
        weather_fl: '1级',
        weather_shidu: '81%',
        weather_pm: 23,
        weather_quaily: '优',
        weather_wendu_high: '30℃',
        weather_wendu_low: '20℃',
        weather_date: '2021-08-29',
      },
rightWindows: {
        data: {
          YiQing: {
            dayMaxAllowVisitorNum: 10000,//景区承载量
            millMaxAllowVisitorNum: 3750,//瞬时承载量
          },
          ChangGui: {
            dayMaxAllowVisitorNum: 10000,//景区承载量
            millMaxAllowVisitorNum: 3750,//瞬时承载量
          },
          realTimeVisitorNum: 2500,//实时客流量
          realTimeRestParkingNum: 100,//剩余停车位
          comfort: '舒适', //舒适度
        },
        orderQRShow: false,
      },
}
},
methods: {
    handleSubMenuClick(path) {
console.log(path);
if (path == 'https://www.mmjcjq.com/vr/') {
return false
}
     else if (path.startsWith('http')) {
        window.open(path, '_blank')
      } 
else {
        this.$router.push(path)
      }
    },
    handleShowSearch() {
      this.showSearch = true
    },
    handleHideSearch() {
      this.showSearch = !this.showSearch
      this.searchKeyWord = ''
    },
    handleSearch() {
      if (this.searchKeyWord == '') {
        this.$message('请输入关键字')
      } else {
        this.$router.push('/article/search/' + encodeURI(this.searchKeyWord))
        this.handleHideSearch()
      }
    },
    handleLogin() {
      this.$store.commit('redirect_url', this.$route.path)
      this.$router.push('/user/login')
    },
    handleLogout() {
      this.$store.commit('logout')
    },
    handlePersonalCenter() {
      this.$router.push('/user/center')
    },
    handleCommandForLang(command) {
      window.location.href = command
    },
    // 天气
    async getWeather() {
      let response = await this.$apiUtil.getWeather('', '', '吴江', '')
console.log(response)
      if (response.returnCode === 1 && response.result) {
        this.weather = response.result
      }
    },
    async initMenuData() {
      this.defaultActive = this.$route.path
      let result = await this.$apiUtil.getNodeList(38, 2)
      this.topMenuList = result.resultList
    },
handleShowQR() {
      this.rightWindows.orderQRShow = !this.rightWindows.orderQRShow
    },
    async getGoodsList(tag_id) {
      let result = await this.$apiUtil.yzGoodsApi.getGoodsList(1, 4, tag_id, '')
      if (result.returnCode === 1 && result.result) {
        this.yzsInfo.productList = result.result.list
      }
    },
    async getYesterdayTodayAndTimeData() {
      //  实时客流及舒适度
      let result = await this.$apiUtil.bigDataApi.getYesterdayTodayAndTimeData()
console.log(result);
      if (result.returnCode === 1 && result.result) {
        this.rightWindows.data.realTimeVisitorNum = result.result.f_real_count//实时客流量
        this.rightWindows.data.realTimeRestParkingNum = result.result.rest_park_count//剩余停车位
        // this.rightWindows.data.YiQing.dayMaxAllowVisitorNum = result.result.f_today_count//景区承载量
        this.rightWindows.data.comfort = result.result.comfortDesc//景区舒适度
      }
    },
  },
  watch: {
    $route() {
      this.defaultActive = this.$route.path
    },
  },
  mounted() {
    this.initMenuData()
    this.getWeather()
    this.getYesterdayTodayAndTimeData()
  }
}
</script>
<style lang="less">
// 鼠标悬浮 二级菜单样式
.el-dropdown-menu,
.el-menu--popup {
  background-color: white !important;
  opacity: 0.9;
}

// 全部弹窗的背景色关闭
.el-popper {
  background: none !important;
  border: none !important;
}

.el-menu--popup {
  min-width: 120px !important;
  text-align: center;
}

.el-menu--popup>.my-el-menu-item {
  font-size: 16px !important;
}

.my-el-menu-item:hover,
.my-el-dropdown-item:hover {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.my-el-dropdown-item {
  color: white !important;
}
</style>
<style scoped lang="less">
.link {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.header {
  width: 100%;
  height: 100px;
  background: rgb(51, 51, 51);
  color: white;
  display: flex;
  padding-top: 20px;
  justify-content: space-between;
  position: relative;
}

.left {
  display: flex;
}

.weather {
  display: flex;
  overflow: hidden;
  font-family: Alimama ShuHeiTi;
  font-weight: bold;
  margin-left: -50px;
}

.weather .weather_img {
  width: 30px;
  height: 30px;
  margin-left: 100px;
  margin-top: 10px;
  margin-right: 10px;
}

.weather .weather_quaily {
  background: rgb(64, 179, 86);
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin-left: 10px;
  margin-right: 40px;
  margin-top: 12px;
  border-radius: 5px;
}

.center {
  display: flex;
  margin-top: 14px;
}

.center div {
  margin-left: 15px;
  margin-right: 15px;
  font-family: Alimama ShuHeiTi;
  font-weight: bold;
}

.center span {
  color: orange;
  font-family: Alimama ShuHeiTi;
  font-weight: bold;
  font-size: 20px;
}

.right {
  height: 73px;
  line-height: 73px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .div-search {
    width: 150px;
    margin-right: 6px;
    margin-top: -10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #1c4059;
      min-width: 33px;
      height: 33px;
      border-radius: 4px;

      .search_img {
        width: 33px;
        height: 33px;
      }

      .input-search {
        height: 33px;
        font-size: 14px;
        width: 120px;
        background: #ffffff;
        border-radius: 4px 0 0 4px;

        ::v-deep .el-input__inner {
          border: 0;
          height: 33px;
          line-height: 33px;
          color: #1c425e;
          font-weight: 400;
          user-select: none;
        }
      }
    }
  }
}

.language {
  margin-left: 20px;
  display: flex;
  align-items: center;
  margin-top: -10px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #D6D6D6;

  .language_img {
    margin-right: 10px;
    margin-left: 20px;
    display: block;
  }

  .el-dropdown-link {
    color: white;
  }
}

.login-div {
  height: 85px;

  .login {
    font-family: Alimama ShuHeiTi;
    font-weight: bold;
    color: white;
    margin-left: 30px;
    margin-right: 30px;
  }

  .user-info {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .head-img {
      margin-top: 10px;
    }

    .logout {
      width: 30px;
      height: 30px;
      margin-top: -15px;
    }
  }
}

.menu {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: Alimama ShuHeiTi !important;
  width: 81%;
  position: absolute;
  top: 80px;
  left: 200px;
  color: black;
}

::v-deep {

  //去除鼠标滑过菜单的动画效果
  .el-menu .el-menu-item:hover {
    background-color: transparent !important;
  }

  .el-sub-menu__title {
    height: 67px !important;
    line-height: 73px !important;
    font-size: 20px !important;
    border-bottom: none !important;
    font-weight: 600;
    padding: 0 20px;
    margin-left: 54px;
  }

  .el-menu--horizontal>.my-el-menu-item {
    height: 73px !important;
    line-height: 73px !important;
    font-size: 20px !important;
    border-bottom: none !important;
    font-weight: 600;
  }

  .el-menu--horizontal>.el-menu-item.is-active {
    border-bottom: none !important;
  }

  .el-menu.el-menu--horizontal {
    display: inline;
    //position: fixed;
    border-radius: 40px;
    box-shadow: 0 0 10px #ccc;
  }

}

.menu_logo {
  border-radius: 50%;
}

.menu_img {
  width: 120px;
  height: 60px;
  margin-left: 50px;
  margin-right: 150px;
}
</style>