import axios from './axios.js'
/**
 * 门票预约相关接口
 */
let ticketApi = {
	/**
	 * 查询可预约景点列表
	 */
	getSpots: async function () {
		let result = await axios.get("/api/v1/wx/ticketSpot/getSpots");
		return result;
	},
	/**
	 * 查询景点可预约时间查询
	 */
	getReserveSpotTime: async function (reserveSpotId, startDate) {
		let result = await axios({
			method: 'GET',
			url: "/api/v1/wx/ticketReserveSpotTime/getReserveSpotTime",
			params: {
				reserveSpotId: reserveSpotId,
				startDate: startDate
			}
		});
		return result;
	},

	contact: {
		/**
		 * 查询本人的所有常用联系人，脱敏数据
		 */
		getTicketContactList: async function () {
			let result = await axios({
				method: 'GET',
				url: "/api/v1/wx/ticketContact/getTicketContactList",
				params: {}
			});
			return result;
		},
		/**
		 * 查询本人的指定联系人详情，未脱敏数据，用来修改
		 */
		getTicketContactById: async function (contactId) {
			let result = await axios({
				method: 'GET',
				url: "/api/v1/wx/ticketContact/getTicketContactById",
				params: {
					contactId: contactId
				}
			});
			return result;
		},
		/**
		 * 新增/修改指定常用联系人
		 */
		updateTicketContactById: async function (int_id, cert_type, cert_name, cert_num, tourist_name, phone_num) {
			let result = await axios({
				method: 'POST',
				url: "/api/v1/wx/ticketContact/updateTicketContactById",
				params: {
					int_id: int_id,
					cert_type: cert_type,
					cert_name: cert_name,
					cert_num: cert_num,
					tourist_name: tourist_name,
					phone_num: phone_num,
				}
			});
			return result;
		},
		/**
		 * 新增/修改指定常用联系人
		 */
		deleteTicketContactById: async function (contactId) {
			let result = await axios({
				method: 'GET',
				url: "/api/v1/wx/ticketContact/deleteTicketContactById",
				params: {
					contactId: contactId
				}
			});
			return result;
		},
	},
	order: {

		/**
		 * 新增预约订单，暂不可修改
		 */
		addOrder: async function (int_id, reserve_spot_time_id, tourist_ids, tourist_num, phone_num) {
			let result = await axios({
				method: 'POST',
				url: "/api/v1/wx/ticketReserveOrder/addOrder",
				params: {
					int_id: int_id,
					reserve_spot_time_id: reserve_spot_time_id,
					tourist_ids: tourist_ids,
					tourist_num: tourist_num,
					phone_num: phone_num,
				}
			});
			return result;
		},
		/**
		 * 查询预约订单，分页，游客只能看本人的订单，管理员查询所有
		 */
		getOrderPage: async function (pageIndex, pageSize, phone_num, reserve_date) {
			let result = await axios({
				method: 'GET',
				url: "/api/v1/wx/ticketReserveOrder/getOrderPage",
				params: {
					pageIndex: pageIndex,
					pageSize: pageSize,
					phone_num: phone_num,
					reserve_date: reserve_date,
				}
			});
			return result;
		},
		/**
		 * 查询订单详情，游客只能查询本人的，管理员可以任意查询，两个参数二选一
		 */
		getOrderDetail: async function (orderId, reserve_order_num) {
			let result = await axios({
				method: 'GET',
				url: "/api/v1/wx/ticketReserveOrder/getOrderDetail",
				params: {
					orderId: orderId,
					reserve_order_num: reserve_order_num,
				}
			});
			return result;
		},
		/**
		 * 更新订单状态，用于管理员核销、管理
		 */
		updateOrderStatus: async function (reserve_order_num, status) {
			let result = await axios({
				method: 'GET',
				url: "/api/v1/wx/ticketReserveOrder/updateOrderStatus",
				params: {
					status: status,
					reserve_order_num: reserve_order_num,
				}
			});
			return result;
		},
		/**
		 * 生成订单二维码
		 */
		updateOrderQrcode: async function (orderId) {
			let result = await axios({
				method: 'GET',
				url: "/api/v1/wx/ticketReserveOrder/updateOrderQrcode",
				params: {
					orderId: orderId
				}
			});
			return result;
		},
	}
}

export default ticketApi;
