<template>
  <mainTop :showTitle="true"></mainTop>

  <div class="article-list">
    <el-row>
      <el-col :span="4"></el-col>
      <el-col :span="16">
        <!--   分页列表     -->

        <div v-if="page.total > 0" class="page block">
          <template v-for="(item, index) in page.list" :key="index">
            <el-card class="page-card">
              <el-row>
                <el-col :span="10">
                  <div class="boss">
                    <div class="page-card-right-time">
                      <div class="time1">{{ item.create_time.substr(5, 5) }}</div>
                      <div class="time2">{{ item.create_time.substr(0, 4) }}</div>
                    </div>
                    <el-image class="page-card-left" :src="baseApiURL + item.font_img_id">
                      <template v-slot:error>
                        <img :src="require('../../assets/index/default.png')
                          " />
                      </template>
                    </el-image>
                  </div>
                </el-col>
                <el-col :span="1"></el-col>
                <el-col :span="13">
                  <div class="page-card-right">
                    <div class="page-card-right-title">{{ item.title }}</div>
                    <div class="page-card-right-txt_content">
                      {{ item.txt_content }}
                    </div>
                    <button class="page-card-right-button" @click="handleDetail(item.int_id)">
                      查看详情
                    </button>
                  </div>
                </el-col>
              </el-row>
            </el-card>
          </template>

          <el-pagination class="pagination" @current-change="handleCurrentChange" background
            :current-page="page.currentPage" :page-size="page.pageSize" prev-text="上一页" next-text="下一页"
            layout="prev, pager, next, jumper, total" :total="page.total">
          </el-pagination>
        </div>

        <el-empty v-else description="文章还没有发布哦"></el-empty>
      </el-col>
      <el-col :span="4"></el-col>
    </el-row>
  </div>
</template>

<script>
// 显示栏目下的文章列表
import mainTop from '@/components/article/mainTop'

export default {
  name: 'list',
  components: {
    mainTop,
  },
  data() {
    return {
      // 显示面包屑导航
      baseApiURL: this.$baseApiURL + '/api/v1/file/download?zip=0&uuid=',
      node: {
        int_id: '168',
      },

      page: {
        currentPage: 1,
        pageSize: 5,
        total: 0,
        list: [],
      },
    }
  },
  methods: {
    handleCurrentChange(val) {
      this.page.currentPage = val
      this.getPageList()
    },
    handleDetail(articleId) {
      //第一步定义routeUrl
      // let routeUrl = this.$router.resolve({
      //   path: "/article/detail/" + this.node.int_id + "/" + articleId,
      // });
      // // 第二步：新标签页打开
      // window.open(routeUrl.href, "_blank");

      this.$router.push('/article/detail/' + this.node.int_id + '/' + articleId)
    },
    async getPageList() {
      let result = await this.$apiUtil.getCommonArticlePage(
        this.node.int_id,
        this.page.currentPage,
        this.page.pageSize,
        ''
      )
      if (result.returnCode === 1) {
        this.page.list = result.result.list
        this.page.total = result.result.total
        this.page.currentPage = result.result.pageNum
      }
    },
    init() {
      this.node.int_id = this.$route.params.nodeId
      this.getPageList()
    },
  },

  watch: {
    $route() {
      this.init()
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped lang="less">
.article-list {
  margin-bottom: 200px;

  .page {
    .page-card {
      margin-bottom: 30px;
      background: rgb(242, 248, 249);

      .boss {
        display: flex;
        overflow: hidden;

        .page-card-right-time {
          margin-top: 80px;
          margin-right: 30px;

          .time1 {
            font-size: 28px;
            font-weight: 600;
          }

          .time2 {
            font-size: 20px;
            font-weight: 500;
          }
        }

        .page-card-left {
          width: 400px;
          height: 280px;
        }

      }

      .page-card-right {
        text-align: left;
        font-family: SourceHanSerifCN-Regular, serif !important;

        .page-card-right-title {
          margin: 25px 0;
          font-size: 24px;
          color: #1c425e;
          line-height: 30px;

          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }

        .page-card-right-txt_content {
          text-indent: 2em;
          font-size: 14px;
          color: #333333;
          line-height: 25px;
          height: 100px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
        }

        .page-card-right-button {
          float: right;
          width: 92px;
          height: 29px;
          margin-top: 20px;
          font-size: 14px;
          color: #666666;
          border: none;
          background: url('../../assets/common/article/list_button_bg.png') no-repeat;
          background-size: 92px 29px;
          cursor: pointer;
        }

        .page-card-right-button:hover {
          color: #666666;
        }
      }
    }
  }
}
</style>
