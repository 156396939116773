import axios from './axios.js'
/**
 * 咨询投诉 相关接口
 */
let complainApi = {
	/**
	 * 查询咨询投诉数据，分页，游客只能看本人的，管理员查询所有
	 */
	getComplainPage: async function (pageIndex, pageSize, form_type, status, keyword, serial_num) {
		let result = await axios({
			method: 'GET',
			url: "/api/v1/wx/complain/getComplainPage",
			params: {
				pageIndex: pageIndex,
				pageSize: pageSize,
				form_type: form_type,
				status: status,
				keyword: keyword,
				serial_num: serial_num
			}
		});
		return result;
	},
	/**
	 * 查询指定详情 咨询投诉 游客只能看本人的，管理员查询所有
	 */
	getComplainDetail: async function (int_id) {
		let result = await axios({
			method: 'GET',
			url: "/api/v1/wx/complain/getComplainDetail",
			params: {
				int_id: int_id
			}
		});
		return result;
	},
	/**
	 * 游客 新增、修改新的咨询、投诉
	 */
	addComplain: async function (int_id, contact_name, contact_phone, content, img_uuids, form_type) {
		let result = await axios({
			method: 'GET',
			url: "/api/v1/wx/complain/addComplain",
			params: {
				int_id: int_id,
				contact_name: contact_name,
				contact_phone: contact_phone,
				content: content,
				img_uuids: img_uuids,
				form_type: form_type
			}
		});
		return result;
	},
	/**
	 * 管理员 处理指定的咨询、投诉
	 */
	replyComplain: async function (int_id, reply_content, reply_img_uuids) {
		let result = await axios({
			method: 'POST',
			url: "/api/v1/wx/complain/replyComplain",
			params: {
				int_id: int_id,
				reply_content: reply_content,
				reply_img_uuids: reply_img_uuids
			}
		});
		return result;
	},
}


export default complainApi;
