<template>
  <div class="main">
    <div class="logo">
      <p class="logo-text logo-zh">七都江村文化旅游景区欢迎您</p>
      <p class="logo-text logo-en">
        Welcome to Wujiang Grand Canal Culture Tourism Area
      </p>
    </div>
    <!--登录-->
    <div v-if="formType == 1" class="login-dom login-max">
      <div class="login">
        <div class="login-title">
          <p class="logo-color" :class="{ 'active-login': loginType == 1 }" @click="changeLoginType(1)">
            密码登录</p>
          <p class="logo-color" :class="{ 'active-login': loginType == 2 }" @click="changeLoginType(2)">
            短信登录</p>
        </div>
        <div class="login-form" autocomplete="off">
          <div class="login-box">
            <div class="name">
              <input type="text" placeholder="请输入手机号" v-model="phone" />
            </div>
            <div class="pwd" v-if="loginType === 1">
              <input type="password" placeholder="请输入密码" v-model="password" />
            </div>
            <div class="sms-code" v-else>
              <input type="text" placeholder="请输入验证码" v-model="sms_code" />
              <el-button type="primary" :loading="sms_button_loading" @click="sendCode()">{{ sms_button_msg }}
              </el-button>
            </div>
            <div v-show="errorMsg != ''">{{ errorMsg }}</div>
            <div class="forget-pwd"><a @click="showForm(3)">忘记密码？</a></div>
          </div>
          <input type="button" class="btn" @click="login()" value="登录" />
        </div>
        <p class="register">
          还没有账户？<a @click="showForm(2)" class="register-a">现在注册</a>
        </p>
      </div>
    </div>
    <!--注册-->
    <div v-if="formType == 2" class="login-dom login-max">
      <div class="login">
        <p class="logo-color-2">注册</p>
        <div class="login-form" autocomplete="off">
          <div class="login-box">
            <div v-show="!next">
              <div class="name">
                <input type="text" placeholder="请输入手机号" v-model="phone" />
              </div>
              <div class="sms-code">
                <input type="text" placeholder="请输入验证码" v-model="sms_code" />
                <el-button type="primary" :loading="sms_button_loading" @click="sendCode()">{{ sms_button_msg }}
                </el-button>
              </div>
            </div>
            <div v-show="next">
              <div class="pwd">
                <input type="password" placeholder="请输入密码" v-model="password" />
              </div>
              <div class="pwd">
                <input type="password" placeholder="请再次输入密码" v-model="password2" />
              </div>
            </div>

            <div v-show="errorMsg != ''">{{ errorMsg }}</div>
            <div class="forget-pwd"><a @click="showForm(3)">忘记密码？</a></div>
          </div>
          <input type="button" class="btn" @click="register()" v-model="button_txt" />
        </div>
        <p class="register">
          已有账户？<a @click="showForm(1)" class="register-a">现在登录</a>
        </p>
      </div>
    </div>
    <!--忘记密码-->
    <div v-if="formType == 3" class="login-dom login-max">
      <div class="login">
        <p class="logo-color-2">忘记密码</p>
        <div class="login-form" autocomplete="off">
          <div class="login-box">
            <div v-show="!next">
              <div class="name">
                <input type="text" placeholder="请输入手机号" v-model="phone" />
              </div>
              <div class="sms-code">
                <input type="text" placeholder="请输入验证码" v-model="sms_code" />
                <el-button type="primary" :loading="sms_button_loading" @click="sendCode()">{{ sms_button_msg }}
                </el-button>
              </div>
            </div>
            <div v-show="next">
              <div class="pwd">
                <input type="password" placeholder="请输入密码" v-model="password" />
              </div>
              <div class="pwd">
                <input type="password" placeholder="请再次输入密码" v-model="password2" />
              </div>
            </div>
            <div v-show="errorMsg != ''">{{ errorMsg }}</div>
            <div class="forget-pwd"><a @click="showForm(1)">现在登录</a></div>
          </div>
          <input type="button" class="btn" @click="forgetPwd()" v-model="button_txt" />
        </div>
        <p class="register">
          还没有账户？<a @click="showForm(2)" class="register-a">现在注册</a>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import md5 from "js-md5";

export default {
  name: "login",
  data() {
    return {

      //1 密码登录、2、短信登录
      loginType: 1,
      phone: "",
      password: "",
      password2: "",

      sms_code: "",
      sms_button_msg: "获取验证码",
      sms_button_loading: false,
      sms_button_time: 60,

      errorMsg: "",

      formType: 1,
      next: false,
      button_txt: "下一步",
    };
  },
  methods: {
    async login() {
      let response;
      if (this.loginType === 1) {


        if (this.phone === "" || this.password === "") {
          this.$notify({
            title: '警告',
            message: '手机号或密码不可为空',
            type: 'warning'
          });
          return;
        }

        let pwd = md5(this.password);
        response = await this.$apiUtil.userApi.loginByPhone(this.phone, pwd);
      } else if (this.loginType === 2) {
        if (this.phone === "" || this.sms_code === "") {
          this.$notify({
            title: '警告',
            message: '手机号或验证码不可为空',
            type: 'warning'
          });
          return;
        }

        response = await this.$apiUtil.userApi.loginByPhoneAndCode(this.phone, this.sms_code);
      }

      if (response.returnCode === 1) {
        this.$notify({
          title: '成功',
          message: "登录成功",
          type: 'success'
        });
        // 获取返回的token，存入vuex
        const access_token = response.result.access_token;
        this.$store.commit("login", access_token);
        await this.getMyInfo();
        await this.$router.push(this.$store.state.redirect_url);
      } else {
        this.$notify.info(response.description);
      }
    },
    async sendCode() {
      if (this.phone == "") {
        this.$notify({
          title: '警告',
          message: '请输入手机号',
          type: 'warning'
        });
        return;
      }
      this.sms_button_loading = true;
      this.sms_button_msg = "短信发送中";
      let response = await this.$apiUtil.userApi.sendCode(this.phone);

      if (response.returnCode === 1) {
        this.sms_button_msg = "已发送（" + this.sms_button_time + "s）";

        let timer = setInterval(() => {
          if (this.sms_button_time > 0) {
            this.sms_button_time--;
            this.sms_button_msg = "已发送（" + this.sms_button_time + "s）";
          } else {
            clearInterval(timer);
            this.sms_button_time = 60;
            this.sms_button_msg = "获取验证码";
            this.sms_button_loading = false;
          }
        }, 1000);
      } else {
        this.sms_button_loading = false;
        this.$notify.info(response.description);
      }
    },
    async register() {
      if (this.phone === "") {
        this.$notify({
          title: '警告',
          message: '手机号不可为空',
          type: 'warning'
        });
        return;
      }
      if (this.sms_code === "") {
        this.$notify({
          title: '警告',
          message: '验证码不可为空',
          type: 'warning'
        });
        return;
      }

      if (!this.next) {
        // 执行下一步
        let response = await this.$apiUtil.userApi.checkPhoneAndCode(
            this.phone,
            this.sms_code
        );

        if (response.returnCode === 1) {
          const result = response.result;
          if (result.state === '0') {
            this.next = true;
            this.button_txt = "注册";
            this.$notify({
              title: '成功',
              message: "验证通过",
              type: 'success'
            });
          } else if (result.state === '1') {
            this.$notify({
              title: '警告',
              message: result.desc,
              type: 'warning'
            });
          }
        } else {
          this.$notify({
            title: '警告',
            message: response.description,
            type: 'warning'
          });
        }
      } else {
        // 注册
        if (this.password === "" || this.password2 === "") {
          this.$notify({
            title: '警告',
            message: '输入的密码不可为空',
            type: 'warning'
          });
          return;
        }
        if (this.password !== this.password2) {
          this.$notify({
            title: '警告',
            message: '两次输入的密码不一致',
            type: 'warning'
          });
          return;
        }

        let pwd = md5(this.password);
        let response = await this.$apiUtil.userApi.registerByPhone(
            this.phone,
            pwd,
            this.sms_code
        );

        if (response.returnCode === 1) {
          this.$notify({
            title: '成功',
            message: response.description,
            type: 'success'
          });
          this.formType = 1;
          this.resetForm();
        } else {
          this.$notify({
            title: '警告',
            message: response.description,
            type: 'warning'
          });
        }
      }
    },
    async forgetPwd() {
      if (this.phone == "") {
        this.$notify({
          title: '警告',
          message: "手机号不可为空",
          type: 'warning'
        });
        return;
      }
      if (this.sms_code == "") {
        this.$notify({
          title: '警告',
          message: "验证码不可为空",
          type: 'warning'
        });
        return;
      }

      if (!this.next) {
        // 执行下一步
        let response = await this.$apiUtil.userApi.checkPhoneAndCode(
            this.phone,
            this.sms_code
        );

        if (response.returnCode === 1) {
          const result = response.result;
          if (result.state == '1') {
            this.next = true;
            this.button_txt = "重置密码";
            this.$notify({
              title: '成功',
              message: '验证通过',
              type: 'success'
            });
          } else if (result.state == '0') {
            this.$notify({
              title: '警告',
              message: result.desc,
              type: 'warning'
            });
            return;
          }
        } else {
          this.$notify({
            title: '警告',
            message: response.description,
            type: 'warning'
          });
          return;
        }
      } else {
        if (this.password == "" || this.password2 == "") {
          this.$notify({
            title: '警告',
            message: "输入的密码不可为空",
            type: 'warning'
          });
          return;
        }

        if (this.password != this.password2) {
          this.$notify({
            title: '警告',
            message: "两次输入的密码不一致",
            type: 'warning'
          });
          return;
        }

        let pwd = md5(this.password);
        let response = await this.$apiUtil.userApi.resetPassword(
            this.phone,
            pwd,
            this.sms_code
        );

        if (response.returnCode === 1) {
          this.$notify({
            title: '成功',
            message: response.description,
            type: 'success'
          });
          this.formType = 1;
          this.resetForm();
        } else {
          this.$notify({
            title: '警告',
            message: response.description,
            type: 'warning'
          });
        }
      }
    },
    async getMyInfo() {
      let response = await this.$apiUtil.userApi.getMyInfo();

      if (response.returnCode === 1) {
        this.$store.commit("userInfo", response.result);
      } else {
        this.$notify({
          title: '警告',
          message: response.description,
          type: 'warning'
        });
      }
    },
    showForm(type) {
      this.formType = type;
      this.resetForm();
    },
    changeLoginType(type) {
      this.loginType = type;
      this.resetForm();
    },
    resetForm() {
      this.phone = "";
      this.password = "";
      this.password2 = "";

      this.sms_code = "";
      this.next = false;
      this.button_txt = "下一步";
    },
  },
  mounted() {
    if (this.$store.state.IsLogin) {
      this.$notify({
        title: '成功',
        message: "已登录，跳转中",
        type: 'success'
      });
      this.$router.push(this.$store.state.redirect_url);
    }
  },
};
</script>
<style lang="less" scoped>
.main {
  background: url("../../assets/user/login.jpg") 0 0 no-repeat;
  background-size: 100% 100%;
  height: 100vh;

  .logo {
    position: absolute;
    top: 20%;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: left;

    .logo-text {
      margin-left: 20px;
      margin-right: 20px;
      font-weight: 400;
      color: #ffffff;
    }

    .logo-zh {
      margin-top: 70px;
      line-height: 48px;
      font-size: 48px;
      font-family: MaShanZhengMaoBiKaiTi, serif;
      letter-spacing: 5px;
    }

    .logo-en {
      margin-bottom: 70px;
      line-height: 24px;
      font-size: 24px;
    }
  }

  .login-dom {
    position: absolute;
    left: 60%;
    width: 40%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    .login {
      width: 80%;
      margin: 180px auto;


      .login-title {
        width: 80%;
        margin: auto;
        display: flex;
        align-items: baseline;
        justify-content: space-between;

        .logo-color {
          letter-spacing: 10px;
          color: #FFFFFF;

          margin: 0;
          height: 50px;
          line-height: 47px;
          font-size: 47px;
          font-family: MaShanZhengMaoBiKaiTi, serif;
          font-weight: 400;
        }

        .active-login {
          color: #f3c21f;
          border-bottom: 4px solid #f3c21f;
        }
      }

      .logo-color-2 {
        letter-spacing: 10px;
        color: #f3c21f;
        margin: 0;

        height: 54px;
        line-height: 47px;
        font-size: 47px;
        font-family: MaShanZhengMaoBiKaiTi, serif;
        font-weight: 400;
      }

      .login-form {
        .login-box {
          width: 80%;
          margin: 120px auto 60px auto;
          cursor: pointer;

          /* 登录名 */

          .name,
          .pwd {
            height: 80px;

            input {
              width: 100%;
              height: 45px;

              text-indent: 20px;
              font-size: 18px;
              outline: none;
              border: none;
              background-color: white;
              border-radius: 5px;
            }

            input.input-error {
              background-color: transparent;
            }
          }

          .sms-code {
            height: 80px;
            display: flex;
            align-items: baseline;
            justify-content: space-between;

            input {
              width: 300px;
              height: 45px;

              text-indent: 20px;
              font-size: 18px;
              outline: none;
              border: none;
              background-color: white;
              border-radius: 4px 0 0 4px;
            }

            .el-button {
              width: 190px;
              height: 47px;

              padding: 0;
              font-size: 18px;
              background: #151515;
              border: #151515;
              border-radius: 0 4px 4px 0;
            }
          }

          .forget-pwd {
            margin: 0 auto;
            float: right;
            font-size: 18px;
            font-weight: 400;
            color: #f3c21f;
          }

          #error-msg {
            color: #f3c21f;
            background-color: rgba(0, 0, 0, 0.6);

            margin-top: 20px;
            padding-left: 5px;
            float: left;
            font-size: 18px;
          }
        }

        /* 登录按钮 */

        .btn {
          opacity: 1;

          border-radius: 5px;
          font-weight: bold;

          display: block;
          width: 80%;
          height: 50px;
          line-height: 50px;
          font-size: 22px;
          letter-spacing: 10px;

          color: #fff;
          background-color: #f3c21f;
          margin: 20px auto;
          cursor: pointer;
        }

        .login-btn {
          margin-top: 35px;
        }
      }
    }

    .register {
      color: #ffffff;
      margin-left: 0px;
      padding-top: 0px;
      cursor: pointer;
      font-size: 16px;

      .register-a {
        color: #f3c21f;
        cursor: pointer;
      }
    }
  }
}

a:hover {
  text-decoration: underline;
}
</style>