const yzOrderDict = {
	// 物流类型 0:快递发货; 1:到店自提; 2:同城配送; 9:无需发货（虚拟商品订单）
	express_type: [{
		key: 0,
		value: "快递发货"
	}, {
		key: 1,
		value: "到店自提"
	}, {
		key: 2,
		value: "同城配送"
	}, {
		key: 9,
		value: "无需发货"
	}]
};

export default yzOrderDict;
