/*
 * @Author: your name
 * @Date: 2021-07-30 14:41:58
 * @LastEditTime: 2021-08-27 17:26:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /szpwz_official_web/src/main.js
 */
import { createApp } from 'vue'
import App from './App.vue'

import router from "./router/index";
import store from "./store/index";
// import vuex from 'vuex'

import element from "@/plugins/element";
import "./styles/index.less";

import apiUtil from "@/api/apiUtil";

import 'video.js/dist/video-js.css'  //按官网引会找不到然后报错，换成下面那个
// require('vue-video-player/node_modules/video.js/dist/video-js.css')

const app = createApp(App);
// app.use(vuex);
app.use(store);
app.use(router);
element(app);

app.config.globalProperties.$apiUtil = apiUtil;
app.config.globalProperties.$baseApiURL = process.env.VUE_APP_API_BASE;

app.mount('#app')

window.Vue = app;

