<template>
  <mainTop :showTitle="false"></mainTop>

  <div class="article-detail">
    <el-row>
      <el-col :span="5"></el-col>

      <el-col :span="14">
        <div v-if="article.title !== ''">
          <!--   标题   -->
          <div class="title">
            <span class="title_zh">{{ article.title }}</span>
          </div>
          <!--   时间   -->
          <div class="time">
            <span>发布时间：{{ article.create_time.substr(0, 10) }}</span>
          </div>

          <!--   内容   -->
          <div v-html="article.content" class="content"></div>
        </div>

        <el-empty v-else description="文章不存在或已删除"></el-empty>
      </el-col>
      <el-col :span="5"></el-col>
    </el-row>
  </div>
</template>

<script>
// 显示栏目下的指定id 的文章详情

import mainTop from '@/components/article/mainTop'

export default {
  name: 'detail',
  components: {
    mainTop,
  },
  data() {
    return {
      articleId: '',
      article: {
        int_id: 1,
        title: '',
        content: '',
        txt_content: '',
        file_list: [
          {
            int_id: 10521,
            stateflag: 0,
            time_stamp: '2021-06-23 14:45:21',
            create_time: '2021-06-23 14:45:21',
            file_name: 'proof.php.png',
            file_save_name: 'e1d0e012-9b8c-4dd3-89e8-30836ced6ea2.png',
            file_type: 1,
            file_size: '21.58KB',
            uuid: 'e1d0e012-9b8c-4dd3-89e8-30836ced6ea2',
            file_web_path:
              '/file/download?uuid=e1d0e012-9b8c-4dd3-89e8-30836ced6ea2',
            file_zip_web_path:
              '/file/download?uuid=e1d0e012-9b8c-4dd3-89e8-30836ced6ea2&zip=1',
          },
        ],
      },
    }
  },
  methods: {
    async getDetailById() {
      let result = await this.$apiUtil.getCommonArticleDetail(this.articleId)
      if (result.returnCode === 1 && result.result) {
        // result.result.content = result.result.content.replace(/<img/gi, "<img style='max-width:100%;height:auto!important;'");
        // result.result.content = result.result.content.replace(/<video/gi, "<video style='max-width:100%;height:auto!important;'");
        this.article = result.result
      }
    },
    init() {
      this.articleId = this.$route.params.articleId
      this.getDetailById()
    },
  },

  watch: {
    $route() {
      this.init()
    },
  },
  mounted() {
    this.init()
  },
}
</script>
<style scoped lang="less">
.article-detail {
  margin-bottom: 200px;

  .title {
    .title_zh {
      font-size: 36px;
      font-weight: 400;
      color: #1c425e;
    }
  }

  .time {
    font-size: 16px;
    font-weight: 400;
    color: #999999;
    line-height: 16px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 50px;
  }

  .content {
    min-height: 400px;
    // 设置为默认值，屏蔽继承父级的center
    text-align: initial;
    color: #333333;
    line-height: 24px;
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, sans-serif !important;
  }
}
</style>
