<!--
 * @Author: your name
 * @Date: 2021-08-26 15:45:17
 * @LastEditTime: 2021-08-29 14:12:45
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /szpwz_official_web/src/views/videoAppreciation/index.vue
-->

<template>
  <mainTop :showTitle="true"></mainTop>
  <div class="list">
    <el-row>
      <el-col :span="3"></el-col>
      <el-col :span="18">
        <!--   分页列表     -->
        <div v-if="page.total > 0" class="page block">
          <div class="page-video-wrap">
            <template v-for="(item, index) in page.list" :key="index">
              <div class="page-card" @click="handelPlayVideo(item)">
                <el-image class="play" :src="require('@/assets/videoAppreciation/video_play.svg')"></el-image>
                <el-image class="page-card-top" :src="baseApiURL + item.font_img_id">
                  <template v-slot:error>
                    <img :src="require('../../assets/index/default.png')
                      " />
                  </template>
                </el-image>

                <div class="page-card-bottom">
                  <div class="page-card-bottom-title">{{ item.title }}</div>
                </div>
              </div>
            </template>
          </div>

          <div class="split"></div>
          <el-pagination class="pagination" @current-change="handleCurrentChange" background
            :current-page="page.currentPage" :page-size="page.pageSize" prev-text="上一页" next-text="下一页"
            layout="prev, pager, next, jumper, total" :total="page.total">
          </el-pagination>
        </div>

        <el-empty v-else description="视频还没有发布哦"></el-empty>
      </el-col>
      <el-col :span="3"></el-col>
    </el-row>
  </div>

  <el-dialog title="" v-model="dialogVisible" width="60%" :before-close="handleClose">
    <video-player ref="videoPlayer" v-if="dialogVisible" :options="videoOptions" />
  </el-dialog>
</template>
<script>
import mainTop from "@/components/article/mainTop";
import VideoPlayer from "@/components/VideoPlayer.vue";
export default {
  name: "index",
  components: {
    mainTop,
    VideoPlayer,
  },
  data() {
    return {
      // 显示面包屑导航
      baseApiURL: this.$baseApiURL + "/api/v1/file/download?zip=0&uuid=",
      node: {
        int_id: "168",
      },

      page: {
        currentPage: 1,
        pageSize: 12,
        total: 0,
        list: [],
      },

      dialogVisible: false,
      videoOptions: {
        autoplay: true,
        controls: true,
        aspectRatio: "16:9", // 播放器高宽占比（例如"16:9"或"4:3"）
        sources: [
          {
            src: "/path/to/video.mp4",
            type: "video/mp4",
          },
        ],
      },
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.page.currentPage = val;
      this.getPageList();
    },
    async getPageList() {
      let result = await this.$apiUtil.getCommonArticlePage(
        this.node.int_id,
        this.page.currentPage,
        this.page.pageSize,
        ""
      );
      if (result.returnCode === 1) {
        this.page.list = result.result.list;
        this.page.total = result.result.total;
        this.page.currentPage = result.result.pageNum;
      }
    },
    handelPlayVideo(video) {
      this.videoOptions["sources"][0]["src"] = this.baseApiURL + video.file_ids; //重新赋值视频地址
      this.dialogVisible = true;
    },
    handleClose(done) {
      this.$refs.videoPlayer.pause();
      done();
    },
    init() {
      this.node.int_id = this.$route.params.nodeId;
      this.getPageList();
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style scoped lang="less">
.list {
  margin-bottom: 200px;

  .page {
    display: flex;
    flex-direction: column;
    align-items: center;

    .page-video-wrap {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      justify-content: flex-start;
      width: 1400px;

      .page-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        width: 325px;
        height: 400px;
        background: #ffffff;
        box-shadow: 0px 0px 18px 0px rgba(1, 47, 79, 0.23);
        border-radius: 4px;
        margin: 12px;

        .play {
          display: block;
          position: absolute;
          z-index: 99;
          width: 72px;
          height: 72px;
          margin-top: 128px;
          opacity: 0.9;
        }

        .page-card-top {
          width: 325px;
          height: 330px;
          border-radius: 4px 4px 0 0;
        }

        .page-card-bottom {
          width: 295px;
          height: 55px;
          border-radius: 4px;

          text-align: center;
          font-family: SourceHanSerifCN-Regular, serif !important;

          .page-card-bottom-title {
            width: 100%;
            height: 40px;
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;

            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
      }
    }

    .split {
      margin-top: 40px;
      width: 1200px;
      height: 1px;
      background: #b4b3b3;
    }
  }
}
</style>