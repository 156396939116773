<!--
 * @Author: your name
 * @Date: 2021-08-28 17:46:28
 * @LastEditTime: 2021-08-30 14:45:16
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /szpwz_official_web/src/views/user/center/onlineInteraction.vue
-->
<template>
    <div class="page" v-if="page.total > 0" v-show="!showDetail">
      <div class="list">
        <div class="list-item" v-for="(item, index) in page.list" :key="index">
          <div class="list-item-top">
            <div class="title">流水号：{{ item.serial_num }}</div>
            <div class="state">
              <span v-show="item.status == 0">未处理</span>
              <span v-show="item.status == 1">已处理</span>
            </div>
          </div>
          <div class="list-item-bottom">
            <div class="title">互动类型：{{ getTypeName(item) }}</div>
            <div class="time">提交时间：{{ item.create_time }}</div>
            <div class="content">内容：{{ item.content }}</div>
            <div class="a">
              <a @click="handelDetail(item)">查看详情</a>
            </div>
          </div>
        </div>
      </div>
  
      <el-pagination
        class="pagination"
        @current-change="handleCurrentChange"
        background
        :current-page="page.currentPage"
        :page-size="page.pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="prev, pager, next, jumper, total"
        :total="page.total"
      >
      </el-pagination>
    </div>
    <el-empty v-else description="您还没有投诉"> </el-empty>
  
    <div class="detail" v-show="showDetail">
      <div class="back">
        <a @click="goBackToList()">返回列表</a>
      </div>
      <div class="base-info">
        <div><span>流水号：</span>{{ detail.serial_num }}</div>
        <div><span>互动类型：</span>{{ getTypeName(detail) }}</div>
        <div><span>联系人：</span>{{ detail.contact_name }}</div>
        <div><span>联系电话：</span>{{ detail.contact_phone }}</div>
        <div><span>提交时间：</span>{{ detail.create_time }}</div>
  
        <div>
          状态：
          <span v-show="detail.status == 0">未处理</span>
          <span v-show="detail.status == 1">已处理</span>
        </div>
      </div>
      <div class="desc">
        <span>提交内容：</span>
        <div class="content">
          <div class="content-txt">
            {{ detail.content }}
          </div>
  
          <img
            v-for="(item, index) in img_uuid_list"
            :key="index"
            class="content-img"
            :src="baseApiURL + item"
            mode="aspectFit"
            show-menu-by-longpress="true"
          />
        </div>
      </div>
      <div class="reply">
        <span class="title">回复内容：</span>
        <span>{{ detail.reply_time }}</span>
  
        <div class="content">
          <div class="content-txt">
            {{ detail.reply_content }}
          </div>
  
          <img
            v-for="(item, index) in reply_img_uuid_list"
            :key="index"
            class="content-img"
            :src="baseApiURL + item"
            mode="aspectFit"
            show-menu-by-longpress="true"
          />
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    name: "touristComplaint",
    data() {
      return {
        baseApiURL: this.$baseApiURL + "/api/v1/file/download?uuid=",
  
        page: {
          currentPage: 1,
          pageSize: 5,
          total: 0,
          list: [],
        },
  
        showDetail: false,
        detail: {},
        typeList: [],
  
        img_uuid_list: [],
        reply_img_uuid_list: [],
      };
    },
    methods: {
      handleCurrentChange(val) {
        this.page.currentPage = val;
        this.getPageList();
      },
      async getPageList() {
        let result = await this.$apiUtil.complainApi.getComplainPage(
          this.page.currentPage,
          this.page.pageSize,
          "",
          "",
          "",
          ""
        );
        if (result.returnCode === 1) {
          this.page.list = result.result.list;
          this.page.total = result.result.total;
          this.page.currentPage = result.result.pageNum;
        }
      },
      async handelDetail(item) {
        let result = await this.$apiUtil.complainApi.getComplainDetail(
          item.int_id
        );
        if (result.returnCode === 1) {
          this.detail = result.result;
          if (result.result.img_uuids) {
            this.img_uuid_list = result.result.img_uuids.split(",");
          }
          if (result.result.reply_img_uuids) {
            this.reply_img_uuid_list = result.result.reply_img_uuids.split(",");
          }
          this.showDetail = true;
        } else {
          this.$message(result.description);
        }
      },
      async getTypeDict() {
        let response = await this.$apiUtil.getDictByParentName("我要投诉类别");
        if (response.returnCode === 1 && response.resultList) {
          this.typeList = response.resultList;
        }
      },
      goBackToList() {
        this.showDetail = false;
      },
      getTypeName(item) {
        let type = this.typeList.find((type) => type.value == item.form_type);
        if (type) {
          return type.name;
        }
      },
    },
    beforeMount() {},
    mounted() {
      this.getTypeDict();
      this.getPageList();
    },
  };
  </script>
  <style lang="less" scoped>
  .page {
    .list {
      min-height: 600px;
      .list-item {
        border: 1px solid #fdd78b;
        margin: 10px 0;
  
        .list-item-top {
          height: 40px;
          background: #fdf8ee;
          padding: 0 20px;
  
          font-size: 16px;
          font-weight: 400;
          color: #fdc75a;
          line-height: 24px;
  
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .list-item-bottom {
          padding: 30px 20px 20px 20px;
  
          display: flex;
          align-items: center;
          justify-content: space-between;
  
          div {
            text-align: left;
          }
  
          .title {
            width: 120px;
          }
  
          .content {
            width: 300px;
  
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
          }
  
          .a {
            text-align: right;
  
            a {
              cursor: pointer;
              padding: 5px;
  
              font-size: 14px;
              font-weight: 400;
              color: #ff7800;
              line-height: 24px;
              background: none;
              border: none;
            }
          }
        }
      }
    }
  
    :deep(.el-pagination) {
      margin-top: 40px !important;
  
      .btn-prev,
      .btn-next,
      .el-pagination__jump,
      .el-pagination__total {
        border: 1px solid #fdd78b !important;
      }
  
      .el-pager {
        .active {
          background-color: #fdc75a !important;
        }
  
        .number {
          border: 1px solid #fdd78b !important;
        }
      }
    }
  }
  
  .detail {
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    line-height: 49px;
    text-align: left;
  
    span {
      width: 150px;
    }
  
    .base-info {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      text-align: left;
  
      div {
        width: 40%;
      }
    }
  
    .desc {
      margin-top: 40px;
  
      .content {
        border: 1px solid #c9c9c9;
        padding: 0 10px;
        .content-img {
          max-width: 250px !important;
          height: auto;
        }
      }
    }
    .reply {
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
  
      .title {
        color: #fdc75a;
      }
  
      .content {
        border: 1px solid #c9c9c9;
        padding: 10px;
        width: 100%;
  
        .content-txt {
          line-height: 30px;
        }
  
        .content-img {
          max-width: 250px !important;
          height: auto;
        }
      }
    }
  
    .back {
      a {
        background-color: #fdf8ee;
        border: none;
        color: #ff7800;
        cursor: pointer;
        padding: 5px;
      }
    }
  }
  </style>