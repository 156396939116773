/*
 * @Author: your name
 * @Date: 2021-07-30 14:42:04
 * @LastEditTime: 2021-08-28 17:52:38
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /szpwz_official_web/src/router/index.js
 */
import {createRouter, createWebHistory} from "vue-router";

const routes = [
    {
        path: "/",
        component: require('../views/index.vue').default,
    },
    {
        //首页
        path: "/index",
        component: require('../views/index.vue').default,
    },
    {
        //模板文章列表页
        path: "/article/list/:nodeId",
        component: require('../views/article/list').default,
    },
    {
        //模板栏目详情页
        path: "/article/listDetail/:nodeId",
        component: require('../views/article/listDetail').default,
    },

    {   //模板文章详情页
        path: "/article/detail/:nodeId/:articleId",
        component: require('../views/article/detail').default,
    },
    {
        //文章搜索页面
        path: "/article/search/:keyword",
        component: require('../views/article/search').default,
    },
    {
        //在线预订 主页
        path: "/onlineBooking/index",
        component: require('../views/onlineBooking/index').default,
    },
    {
        //在线预订 商品搜索
        path: "/onlineBooking/goodsSearch/:tag_id",
        component: require('../views/onlineBooking/goodsSearch').default,
    },
    {
        //在线互动详情页
        path: "/onlineInteraction/interaction/:nodeId",
        component: require('../views/onlineInteraction/interaction').default,
    },
    {
        //旅游投诉详情页
        path: "/touristComplaint/index/:nodeId",
        component: require('../views/touristComplaint/index').default,
    },
    {
        //全景导览 主页
        path: "/panoramicTour/index/:nodeId",
        component: require('../views/panoramicTour/index').default,
    },
    {
        //视频欣赏 主页
        path: "/videoAppreciation/index/:nodeId",
        component: require('../views/videoAppreciation/index').default,
    },
    {
        //登录页
        path: "/user/login",
        component: require('../views/user/login').default,
        meta: {
            "hideHeader": true
        }
    },
    {
        //个人中心
        path: "/user/center",
        component: require('../views/user/center/index').default,
        meta: {
            "hideHeader": false
        },
        children: [
            {
                path: 'account',
                component: require('../views/user/center/account').default,
            },
            {
                path: 'myGoodsOrder',
                component: require('../views/user/center/myGoodsOrder').default,
            },
            {
                path: 'myTicketOrder',
                component: require('../views/user/center/myTicketOrder').default,
            },
            {
                path: 'onlineInteraction',
                component: require('../views/user/center/onlineInteraction').default,
            },
            {
                path: 'touristComplaint',
                component: require('../views/user/center/touristComplaint').default,
            }
        ]
    },
    {
        path: '/:catchAll(.*)',
        component: require('../components/404.vue').default
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.afterEach(() => {
    window.scrollTo(0, 0);
})
export default router
