<template>
    <mainTop :showTitle="true"></mainTop>

    <div class="article-detail">
        <el-row>
            <el-col :span="5"></el-col>

            <el-col :span="14">
                <div v-if="article.title !== ''">
                    <!-- 标题   -->
                    <div class="title">
                        <span class="title_zh">{{ article.title }}</span>
                    </div>

                    <!--   内容   -->
                    <div v-html="article.content" class="content"></div>
                </div>

                <el-empty v-else description="文章不存在或已删除"></el-empty>
            </el-col>
            <el-col :span="5"></el-col>
        </el-row>
    </div>

    <div class="article_border">
        <div class="content">
            <el-form :model="articleRuleForm" :rules="articleRules" ref="articleForm" label-width="100px"
                class="demo-ruleForm">
                <el-form-item label="类型：" prop="form_type" class="labelCategory">
                    <el-select :popper-append-to-body="false" v-model="articleRuleForm.form_type" placeholder="请选择"
                        @change="handleChange2()" clearable :options="resultLists" :props="optionProps2"
                        style="width: 202px;">
                        <el-option v-for="item in resultLists" :key="item.name" :label="item.name" :value="item.value"
                            class="beforecontent">
                        </el-option>
                    </el-select>
                </el-form-item>
                <div class="textName">
                    <el-form-item label="联系人：" prop="contact_name" class="articleName">
                        <el-input v-model="articleRuleForm.contact_name" class="inputName"></el-input>
                    </el-form-item>
                    <el-form-item label="联系方式：" prop="contact_phone" class="articlePhone">
                        <el-input v-model="articleRuleForm.contact_phone" class="inputPhone"></el-input>
                    </el-form-item>
                </div>
                <div class="textareaBor">
                    <el-form-item label="问题描述：" prop="content" class="articleContent">
                        <el-input :label-position="leftPosition" type="textarea" v-model="articleRuleForm.content"
                            maxlength="200" show-word-limit class="articleTextarea"
                            placeholder="请填写详细描述以便我们提供更好的帮助。"></el-input>
                    </el-form-item>
                </div>
                <div class="UploadPictures">
                    <el-form-item label="上传图片：" class="atriclePicture">
                        <el-upload ref="upload" class="uploadDemo" :action="dialogImageUrl" list-type="picture-card"
                            :on-preview="handlPreview" :on-remove="handleRemove" :on-success="handleSuccess"
                            :multiple="true">
                            <div class="Pictures">
                                <img src="../../assets/index/zaixianhudong_shangshang.png" alt="" class="Pictures" />
                            </div>
                        </el-upload>
                    </el-form-item>
                </div>
                <div class="bottom">
                    <el-form-item class="buttomBox">
                        <el-button type="" @click="submitForm" class="btn1">提交</el-button>
                        <el-button type="" class="btn2">重置</el-button>
                    </el-form-item>
                </div>
            </el-form>
        </div>
        <!-- 图片预览 -->
        <el-dialog title="图片预览" v-model="previewVisible" width="50%">
            <img :src="previewPath" alt="" class="previewImg" />
        </el-dialog>
    </div>
</template>
  
<script>
// 栏目 没有文章列表，显示发布的栏目下最新一篇文章详情
import mainTop from '@/components/article/mainTop'

export default {
    name: 'touristComplaint',
    components: {
        mainTop,
    },
    data() {
        // 验证手机号的规则
        var checkMobile = (rule, value, cb) => {
            const regMobile =
                /^((13[0-9])|(14[5-9])|(15([0-3]|[5-9]))|(16[6-7])|(17[1-8])|(18[0-9])|(19[1|3])|(19[5|6])|(19[8|9]))\d{8}$/
            if (regMobile.test(value)) {
                //  合法的手机号
                return cb()
            }
            cb(new Error('请输入合法的手机号'))
        }
        return {
            nodeId: '',
            article: {
                int_id: 1,
                title: '',
                content: '',
                txt_content: '',
                file_list: [
                    {
                        int_id: 10521,
                        stateflag: 0,
                        time_stamp: '2021-06-23 14:45:21',
                        create_time: '2021-06-23 14:45:21',
                        file_name: 'proof.php.png',
                        file_save_name: 'e1d0e012-9b8c-4dd3-89e8-30836ced6ea2.png',
                        file_type: 1,
                        file_size: '21.58KB',
                        uuid: 'e1d0e012-9b8c-4dd3-89e8-30836ced6ea2',
                        file_web_path:
                            '/file/download?uuid=e1d0e012-9b8c-4dd3-89e8-30836ced6ea2',
                        file_zip_web_path:
                            '/file/download?uuid=e1d0e012-9b8c-4dd3-89e8-30836ced6ea2&zip=1',
                    },
                ],
            },
            labelPosition: 'right',
            leftPosition: 'left',
            // 图片的临时地址

            pic: [],
            // 上传图片的url地址
            dialogImageUrl: this.$baseApiURL + '/api/v1/file/upload',
            previewVisible: false,
            // 预览图片
            previewPath: '',
            // 类型数据列表 名称
            optionProps2: {
                value: 'value',
                label: 'name',
            },
            // 类型列表
            resultLists: [],
            // 传参
            parentName: '我要投诉类别',
            // 提交参数
            articleRuleForm: {
                int_id: '',
                contact_name: '',
                contact_phone: '',
                content: '',
                // 图片
                img_uuids: '',
                // 互动的类别
                form_type: '',
            },

            articleRules: {
                form_type: [{ required: true, message: '请输入类型', trigger: 'blur' }],
                content: [{ required: true, message: '请输入内容', trigger: 'blur' }],
                contact_name: [
                    { required: true, message: '请输入联系人', trigger: 'blur' },
                ],
                contact_phone: [
                    {
                        required: true,
                        message: '请输入手机号',
                        trigger: 'blur',
                    },
                    { validator: checkMobile, trigger: 'blur' },
                ],
            },
        }
    },
    methods: {
        // 提交
        submitForm() {
            let _self = this
            // _self.articleRuleForm.img_uuids.substring(
            //   0,
            //   _self.articleRuleForm.img_uuids.length - 1
            // )

            _self.$refs.articleForm.validate(async (valid) => {
                if (!valid) return

                if (_self.pic == '') {
                    return _self.pic
                } else {
                    _self.articleRuleForm.img_uuids = _self.pic.join(',')
                }
                let result = await _self.$apiUtil.complainApi.addComplain(
                    _self.articleRuleForm.int_id,
                    _self.articleRuleForm.contact_name,
                    _self.articleRuleForm.contact_phone,
                    _self.articleRuleForm.content,
                    _self.articleRuleForm.img_uuids,
                    _self.articleRuleForm.form_type
                )
                if (result.returnCode == 1) {
                    _self.$message.success('可到个人中心 旅游投诉 查看互动记录')
                    _self.$refs.articleForm.resetFields()
                    _self.$refs.upload.clearFiles()
                } else {
                    _self.$message.error('提交信息失败')
                }
            })
        },
        // 预览
        handlPreview(file) {
            let _self = this
            _self.previewPath = file.url
            _self.previewVisible = true
        },
        // 上传 成功
        handleSuccess(response) {
            let _self = this
            // 拼接得到一个图片的信息
            _self.pic.push(response.result.uuid)
        },
        // 移除图片
        handleRemove(file) {
            let _self = this
            // 获取将要删除的图片的临时路径
            // debugger
            const uuid = file.response.result.uuid
            const i = _self.pic.findIndex((x) => x === uuid)
            _self.pic.splice(i, 1)
        },
        // 渲染选择器的数据
        async handleChange2() {
            let _self = this
            let result = await _self.$apiUtil.getDictByParentName(_self.parentName)
            if (result.returnCode == 1) {
                _self.resultLists = result.resultList
            }
        },
        async getNodeFirstArticle() {
            let result = await this.$apiUtil.getCommonArticlePage(
                this.nodeId,
                1,
                1,
                '',
                1
            )
            if (
                result.returnCode === 1 &&
                result.result &&
                result.result.list.length === 1
            ) {
                this.article = result.result.list[0]
            }
        },
        init() {
            this.nodeId = this.$route.params.nodeId
            this.getNodeFirstArticle()
        },
    },

    watch: {
        $route() {
            this.init()
        },
    },
    mounted() {
        let _self = this
        _self.handleChange2()
        if (!this.$store.state.IsLogin) {
            this.$message('请先登录')
            this.$router.push('/user/login')
        }
        this.init()
    },
}
</script>
<style scoped lang="less">
.article-detail {
    margin-bottom: 200px;

    .title {
        //display: inline-grid;
        display: none;
        min-width: 500px;
        height: 70px;

        .title_zh {
            font-size: 36px;
            font-weight: 400;
            color: #1c425e;
        }
    }

    .content {
        min-height: 400px;
        // 设置为默认值，屏蔽继承父级的center
        text-align: initial;
        color: #333333;
        line-height: 24px;
        font-size: 16px;
        font-family: SourceHanSansCN-Regular, sans-serif !important;
    }
}
.article_border {
  width: 1200px;
  border: 1px solid #c9c9c9;
  margin: auto;
  margin-bottom: 236px;

  .content {
    margin: 0 auto;

    .labelCategory {
      margin-top: 36px;

      /deep/ .el-form-item__label {
        margin-left: 256px;
        width: 300px;
        font-size: 18px;
        font-family: Source Han Serif CN;
        font-weight: 400;
        color: #ff5a00;
      }
      /deep/ .el-form-item__content{
        text-align: left;
      }
    }

    .textName {
      display: flex;

      .articleName /deep/ .el-form-item__label {
        margin-left: 254px;
        font-size: 14px;
        font-family: Source Han Serif CN;
        font-weight: 400;
        color: #333333;

        .inputName {
          width: 195px;
          height: 30px;
          margin-right: 104px;
        }
      }

      .articlePhone /deep/ .el-form-item__label {
        font-size: 14px;
        font-family: Source Han Serif CN;
        font-weight: 400;
        color: #333333;
        margin-left: 120px;

        .inputPhone {
          width: 195px;
          height: 30px;
        }
      }
    }
    .articleContent /deep/ .el-form-item__content {
        text-align: left;
    }
    .articleContent /deep/ .el-textarea {
        width: 663px;
    }
    .articleContent /deep/ .el-form-item__label {
      margin-left: 252px;
      font-size: 14px;
      font-family: Source Han Serif CN;
      font-weight: 400;
      color: #333333;
    }

    .articleTextarea /deep/ .el-textarea__inner {
      width: 663px;
      height: 221px;
      border: 1px solid #ccc;
    }

    .atriclePicture {

      /deep/ .el-form-item__label {
        margin-left: 252px;
        font-size: 14px;
        font-family: Source Han Serif CN;
        font-weight: 400;
        color: #333333;
      }
      /deep/ .el-form-item__content{
        text-align: left;
      }
    }

    .uploadDemo {
      margin-right: 100px;
    }

    .buttomBox {
      width: 663px;
      margin: 0 auto;

      .btn1 {
        margin-left: 160px;
        background: #1c425e;
        color: #fff;
        text-align: center;

      }

      .btn2 {
        background: #999999;
        color: #fff;
        text-align: center;
      }
    }

    .Pictures {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 46px;
        height: 46px;
      }
    }
  }

  .bottom {
    margin-bottom: 64px;
  }
}

.previewImg {
  width: 1000px;
  height: 1000px;
}
</style>
  