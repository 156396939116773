/*
 * @Author: your name
 * @Date: 2021-06-11 15:42:51
 * @LastEditTime: 2021-08-29 10:54:39
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /szpwz_official_web/src/api/axios.js
 */
import axios from 'axios'
import store from '../store/index.js'

import {ElMessage} from "element-plus";

let instance = axios.create({
    // 设置默认的超时时间
    timeout: 1000 * 12,
    // 设置默认请求头
    // http请求统一配置，withCredentials设置为false。
    // 因为Access-Control-Allow-Credentials设置为true的情况下,Access-Control-Allow-Origin不能设置为*.
    withCredentials: false
});
instance.defaults.baseURL = process.env.VUE_APP_API_BASE;
// post请求头
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
// 请求拦截器
instance.interceptors.request.use(
    req => {
        let token = store.state.access_token;
        if (token) {
            req.headers.common['Authorization'] = 'Bearer ' + token;
        } else {
            req.headers.common['Authorization'] = 'no-login';
        }
        return req;
    }, error => {
        return Promise.reject(error);
    }
)

// 响应拦截器
instance.interceptors.response.use(
    response => {
        if (response.status === 200) {
            // 如果有返回新的access-token，更新本地token
            const new_token = response.headers["Access-token"];
            if (new_token) {
                store.commit("token", new_token)
            }
            return Promise.resolve(response.data);
        }
    },
    error => {
        const {
            response
        } = error;
        if (response) {
            // 请求已发出，但是不在2xx的范围
            if (response.status === 401) {
                store.commit('logout')
                ElMessage("会话过期，请登录")
                return Promise.reject(response.data);
            } else if (response.status === 403) {
                ElMessage("权限不足，请联系管理员")
                return Promise.reject(response.data);
            } else if (response.status > 0) {
                ElMessage("服务器异常")
                return Promise.reject(response.data);
            }
            return Promise.reject(response);
        } else {
            ElMessage("网络异常")
            // 处理断网的情况
            // eg:请求超时或断网时，更新state的network状态
            // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
            // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
            // store.commit('changeNetwork', false)
        }
    }
)

// instance增加all方法、spread方法
instance.all = axios.all;
instance.spread = axios.spread;
export default instance;
