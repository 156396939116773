<!--
 * @Author: your name
 * @Date: 2021-08-28 17:46:28
 * @LastEditTime: 2021-08-29 15:58:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /szpwz_official_web/src/views/user/center/account.vue
-->
<template>
  <ul class="account">
    <li>
      <label>用户头像：</label>
      <el-image class="head-img" :src="user.head_url">
        <template v-slot:error>
          <img :src="require('@/assets/user/default_head_img.png')" />
        </template>
      </el-image>
    </li>
    <li>
      <label>昵称：</label>
      {{ user.nick_name }}
    </li>
    <li>
      <label>手机号：</label>
      {{ user.phone_num }}
    </li>
    <li>
      <label>性别：</label>
      <span v-show="user.gender == '' || user.gender == 0">未知</span>
      <span v-show="user.gender == 1">男</span>
      <span v-show="user.gender == 2">女</span>
    </li>
  </ul>
</template>
<script>
export default {
  name: "account",
  data() {
    return {
      // user: this.$store.state.userInfo,
    };
  },
  computed: {
    user() {
      return this.$store.state.userInfo;
    },
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.account {
  margin: 0;
  padding: 0;

  li {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    margin: 30px 0;

    label {
      text-align: right;
      width: 100px;
      margin-right: 20px;
    }

    .head-img {
      width: 110px;
      height: 110px;
      border-radius: 50%;

      img {
        width: 110px;
      }
    }
  }
}
</style>