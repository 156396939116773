import axios from './axios.js'

// 上传和下载相关接口

let file = {
    // 上传
    upload: async function (file_path) {
        let result = await axios({
            method: 'POST',
            url: "/api/v1/file/upload",
            params: {
                file_path: file_path,   
            }
        });
        return result;
    },
    // 下载
    download: async function (uuid, zip, Authorization) {
        return await axios({
            method: 'GET',
            url: "/api/v1/file/download",
            params: {
                uuid: uuid,
                zip: zip,
                Authorization: Authorization
            }
        });
    }
}
export default file