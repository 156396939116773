<template>
  <div class="article-list">
    <el-row>
      <el-col :span="4"></el-col>
      <el-col :span="16">
        <!--    面包屑导航    -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>搜索结果</el-breadcrumb-item>
        </el-breadcrumb>

        <!--    搜索栏    -->
        <div class="search-keyword">
          <el-input
            placeholder="请输入您想要搜索的关键词"
            v-model="keyword"
            @change="handleCurrentChange(1)"
          ></el-input>
          <el-button icon="el-icon-search"></el-button>
        </div>

        <!--   搜索结果分页列表     -->
        <div v-if="page.total > 0" class="page block">
          <span class="search-result">搜索结果</span>

          <el-timeline>
            <el-timeline-item
              v-for="(item, index) in page.list"
              :key="index"
              color="#002945"
              :timestamp="item.create_time"
              :hide-timestamp="true"
              @click="handleDetail(item)"
            >
              <div class="search-title">{{ item.title }}</div>
              <div class="search-time">
                {{ item.create_time.substr(0, 10) }} | {{ item.node_name }}
              </div>
            </el-timeline-item>
          </el-timeline>

          <el-pagination
            class="pagination"
            @current-change="handleCurrentChange"
            background
            :current-page="page.currentPage"
            :page-size="page.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="prev, pager, next, jumper, total"
            :total="page.total"
          >
          </el-pagination>
        </div>

        <el-empty v-else description="搜索结果为空"></el-empty>
      </el-col>
      <el-col :span="4"></el-col>
    </el-row>
  </div>
</template>

<script>
// 显示栏目下的文章列表
export default {
  name: 'list',
  components: {},
  data() {
    return {
      // 显示面包屑导航
      baseApiURL: this.$baseApiURL + '/api/v1/file/download?uuid=',
      keyword: '',

      page: {
        currentPage: 1,
        pageSize: 5,
        total: 0,
        list: [],
      },
    }
  },
  methods: {
    handleCurrentChange(val) {
      this.page.currentPage = val
      this.getPageList()
    },
    handleDetail(article) {
      //第一步定义routeUrl
      // let routeUrl = this.$router.resolve({
      //   path: "/article/detail/" + article.data_type + "/" + article.int_id,
      // });
      // // 第二步：新标签页打开
      // window.open(routeUrl.href, "_blank");

      this.$router.push(
        '/article/detail/' + article.data_type + '/' + article.int_id
      )
    },
    async getPageList() {
      if (this.keyword.trim() === '') {
        this.$message.error('请输入您想要搜索的关键词')
        return
      }

      let result = await this.$apiUtil.getCommonArticlePage(
        null,
        this.page.currentPage,
        this.page.pageSize,
        this.keyword
      )
      if (result.returnCode === 1) {
        this.page.list = result.result.list
        this.page.total = result.result.total
        this.page.currentPage = result.result.pageNum

        if (this.page.total === 0) {
          this.$message.error('没有您想要的结果')
        }
      } else {
        this.$message.error(result.description)
      }
    },
    init() {
      if (this.$route.params.keyword) {
        this.keyword = this.$route.params.keyword
      }
      this.getPageList()
    },
  },

  watch: {
    $route() {
      this.init()
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped lang="less">
.article-list {
  margin-top: 100px;
  margin-bottom: 200px;

  .el-breadcrumb {
    font-size: 18px;
    font-weight: 400;
    color: #333333;
  }

  .search-keyword {
    text-align: left;
    margin-top: 73px;
    margin-bottom: 65px;

    .el-input {
      width: 615px;
      font-size: 18px;

      ::v-deep .el-input__inner {
        border: 1px #002945 inset;
        border-radius: initial;
        height: 56px;
      }
    }

    .el-button {
      width: 65px;
      height: 56px;
      font-size: 18px;
      color: #ffffff;
      background: rgb(0 41 69 / 70%);
      border: none;
      border-radius: initial;
    }
  }

  .page {
    text-align: initial;

    .search-result {
      display: block;
      width: 92px;
      height: 22px;
      font-size: 22px;
      font-weight: 400;
      color: #002945;
      margin-bottom: 48px;
    }

    .el-timeline {
      padding: 0;

      .el-timeline-item {
        padding-bottom: 36px;
        cursor: pointer;

        ::v-deep .el-timeline-item__tail {
          display: none;
        }
      }

      ::v-deep .el-timeline-item__node {
        margin-top: 8px;
      }

      .search-title {
        height: 22px;
        font-size: 22px;
        font-weight: 400;
        color: #333333;
        display: block;
        margin-bottom: 21px;
      }

      .search-time {
        height: 16px;
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        display: block;
      }
    }
  }
}

.el-empty {
  padding: 50px 0;
}
</style>
