<template xmlns:el-row="http://www.w3.org/1999/html">
  <div class="main">
    <div class="banner">
      <el-image v-if="node.top_banner_img_uuid && node.top_banner_img_uuid.length > 0"
        :src="baseApiURL + node.top_banner_img_uuid"></el-image>
      <el-image v-else class="top-banner" :src="require('@/assets/common/article/banner_article.png')"></el-image>
      <span class="top-title">{{ node.node_name }}</span>
    </div>

    <el-row>
      <el-col :span="4"></el-col>
      <el-col :span="16">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ node.node_name }}</el-breadcrumb-item>
          <el-breadcrumb-item>商品搜索</el-breadcrumb-item>
        </el-breadcrumb>

        <div class="search-bar">
          <el-select v-model="params.select_tag_id" placeholder="请选择分组" @change="handleCurrentChange(1)">
            <el-option v-for="item in goods_tags" :key="item.tag_id" :label="item.name" :value="item.tag_id"></el-option>
          </el-select>
          <el-input placeholder="请输入商品名称" v-model="params.keyword" class="input-with-select"
            @change="handleCurrentChange(1)">
          </el-input>
          <el-button icon="el-icon-search" @click="handleCurrentChange(1)"></el-button>
        </div>
        <div class="goods-list">
          <div v-if="page.total > 0" class="page block">
            <template v-for="(item, index) in page.list" :key="index">
              <el-card class="page-card">
                <el-row>
                  <el-col :span="10">
                    <el-image class="page-card-left" :src="showGoods ? item.mainImageUrl : baseApiURL + item.font_img_id">
                      <template v-slot:error>
                        <img :src="require('../../assets/index/default.png')
                          " />
                      </template>
                    </el-image>
                  </el-col>
                  <el-col :span="1"></el-col>
                  <el-col :span="9">
                    <div class="page-card-center">
                      <div class="page-card-center-title">{{ item.title }}</div>
                      <div class="page-card-center-txt_content">
                        {{ showGoods ? item.sellPoint : item.txt_content}}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="1"></el-col>
                  <el-col :span="3">
                    <div class="page-card-right">
                      <div class="page-card-right-price">
                        <span  v-if="showGoods && item.price!=null">¥ {{ (item.price).toFixed(2) }}</span>
                      </div>

                      <button class="page-card-right-button" @click="handleGoodsClick(item)">
                        立即购买
                      </button>
                    </div>
                  </el-col>
                </el-row>
              </el-card>
            </template>

            <el-pagination class="pagination" @current-change="handleCurrentChange" background
              :current-page="page.currentPage" :page-size="page.pageSize" prev-text="上一页" next-text="下一页"
              layout="prev, pager, next, jumper, total" :total="page.total">
            </el-pagination>
          </div>

          <el-empty v-else description="商品还没有发布哦"></el-empty>
        </div>
      </el-col>
      <el-col :span="4"></el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'onlineBookIndex',
  data() {
    return {
      // 显示面包屑导航
      baseApiURL: this.$baseApiURL + '/api/v1/file/download?uuid=',

      node: {
        parent_id: '',
        parent_node_name: '在线预定',
        parent_previewURL: '',
        // 在线预定 id
        int_id: '159',
        node_name: '商品搜索',
        node_name_en: '',
        description: '',
        previewURL: '',
        top_banner_img_uuid: '',
      },
      page: {
        currentPage: 1,
        pageSize: 3,
        total: 0,
        list: [],
      },
      params: {
        keyword: '',
        select_tag_id: '',
      },
      goods_tags: [
        {
          name: '请选择分组',
          tag_id: '',
        },
        {
          name: '风物特产',
          url: 'https://shop97220858.youzan.com/v2/showcase/tag?alias=3epl5fpmfcjk2',
          tag_id: 287,
        },
        // {
        //   name: '餐饮美食',
        //   url: 'https://shop97220858.youzan.com/v2/showcase/tag?alias=1ylmlgubbwh5e',
        //   tag_id: 222,
        // },
        // {
        //   name: '酒店住宿',
        //   url: 'https://shop97220858.youzan.com/v2/showcase/tag?alias=1yah8ww5cgj5u',
        //   tag_id: 223,
        // },
        // {
        //   name: '线路产品',
        //   url: 'https://shop97220858.youzan.com/v2/showcase/tag?alias=3f0ornkzfttki',
        //   tag_id: 221,
        // },
        // {
        //   name: '游乐项目',
        //   url: 'https://shop97220858.youzan.com/v2/showcase/tag?alias=360r3iq64insy',
        //   tag_id: 227,
        // },
        // {
        //   name: '导游预订',
        //   url: 'https://shop97220858.youzan.com/v2/showcase/tag?alias=274cb8e2okhiq',
        //   tag_id: 226,
        // },
      ],
      showGoods:true,
    }
  },
  methods: {
    async getNodeAndParent() {
      let result = await this.$apiUtil.getNodeAndParent(this.node.int_id)
      if (result.returnCode === 1 && result.result) {
        this.node = result.result
      } else {
        this.node.node_name = '栏目不存在'
        this.$message.error(result.description)
      }
    },
    handleGoodsClick(goods) {
      if(this.showGoods){
        window.open(goods.webDetailUrl, '_blank')
      }else{
        window.open(goods.appPcUrl, '_blank')
      }
      // if (
      //   goods.detail_url &&
      //   !goods.detail_url.startsWith('https://h5.youzan.com')
      // ) {
      //   window.open(goods.detail_url, '_blank')
      // } else {
      //   window.open(
      //     'https://detail.youzan.com/show/goods?alias=' + goods.alias,
      //     '_blank'
      //   )
      // }
    },
    handleCurrentChange(val) {
      this.page.currentPage = val
      this.getGoodsList()
    },
    // 获取电商
    async getGoodsList() {
      if(this.params.select_tag_id == 222 || this.params.select_tag_id == 223 || this.params.select_tag_id == 227 || this.params.select_tag_id == 226){
        this.showGoods = false
        this.getPageList()
      }else{
        this.showGoods = true
        let result = await this.$apiUtil.yzGoodsApi.getGoodsList(
          this.page.currentPage,
          this.page.pageSize,
          this.params.select_tag_id,
          this.params.keyword
        )
        if (result.returnCode === 1 && result.result) {
          this.page.list = result.result.list
          this.page.total = result.result.total
          this.page.currentPage = result.result.pageNum
        }
      }
    },
    // 电商对应关系
    async getGoodsTagList() {
      let res = await this.$apiUtil.yzGoodsApi.getGoodsCategories()  
      // {228 "风物特产"
      //  222 "餐饮美食"
      //  223 "酒店住宿"
      //  221 "线路产品"
      //  227 "游乐项目"
      //  226 "导游预订"}
      res.resultList.forEach((item) => {
        if (item.node_id == 228) {
          this.goods_tags[1].tag_id = item.category_id
        // }else if (item.node_id == 222) {
        //   this.goods_tags[2].tag_id = item.category_id
        // }else if (item.node_id == 223) {
        //   this.goods_tags[3].tag_id = item.category_id
        // }else if (item.node_id == 221) {
        //   this.goods_tags[4].tag_id = item.category_id
        // }else if (item.node_id == 227) {
        //   this.goods_tags[5].tag_id = item.category_id
        // }else if (item.node_id == 226){
        //   this.goods_tags[6].tag_id = item.category_id
        }
      })
    },
    // 获取内容管理发布
    async getPageList() {
      let result = await this.$apiUtil.getCommonArticlePage(
        this.params.select_tag_id,
        this.page.currentPage,
        this.page.pageSize,
        this.params.keyword
      )
      if (result.returnCode === 1 && result.result) {
        this.page.list = result.result.list
        this.page.total = result.result.total
        this.page.currentPage = result.result.pageNum
      }
    },
    init() {
      this.getNodeAndParent()
      
      this.getGoodsTagList().then(()=>{
        this.params.select_tag_id = Number(this.$route.params.tag_id) 
        this.getGoodsList()
      })
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style>
.el-select-dropdown {
  background: #ffffff;
}
</style>

<style scoped lang="less">
.main {
  margin-bottom: 100px;

  .banner {
    min-height: 560px;
    height: 560px;

    ::v-deep .el-image {

      .el-image__inner {
        width: 100vw !important;
        height: 560px !important;
      }
    }

    .top-title {
      display: none;
      position: absolute;
      top: 165px;
      left: 78%;
      writing-mode: tb;
      width: 54px;
      height: 250px;
      font-size: 60px;
      font-family: MaShanZhengMaoBiKaiTi, serif !important;
      font-weight: 400;
      color: #ffffff;
      z-index: 10;
    }
  }

  .el-breadcrumb {
    font-size: 18px;
    margin-top: 12px;
    margin-bottom: 20px;
  }

  .title {
    display: inline-grid;
    min-width: 500px;
    height: 70px;
    background: url('../../assets/common/article/banner_article_node_bg.png') no-repeat center;
    margin-bottom: 60px;
    margin-top: 80px;

    .title_zh {
      font-size: 36px;
      font-weight: 400;
      color: #1c425e;
    }

    .title_en {
      font-size: 16px;
      font-weight: 400;
      color: #dbdbdb;
    }
  }

  .search-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin: 50px 20px 50px 50%;
    color: #999999;
  }

  .page {
    .page-card {
      margin: 20px;
      padding: 30px 20px;
      background: #ffffff;
      box-shadow: 0px 0px 18px 0px rgba(1, 47, 79, 0.23);
      border-radius: 4px;

      .page-card-left {
        width: 460px;
        height: 280px;
      }

      .page-card-center {
        text-align: left;
        font-family: SourceHanSerifCN-Regular, serif !important;

        .page-card-center-title {
          margin: 20px 0;
          font-size: 24px;
          color: #1c425e;
          line-height: 30px;
        }

        .page-card-center-txt_content {
          color: #333333;
          font-size: 14px;
          line-height: 26px;
          height: 200px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 8;
        }
      }

      .page-card-right {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 260px;
        padding-top: 20px;

        .page-card-right-price {
          width: 100%;
          height: 30px;
          font-size: 22px;
          font-family: Source Han Serif CN;
          font-weight: 500;
          color: #ff5a00;
          line-height: 30px;
        }

        .page-card-right-button {
          width: 120px;
          height: 40px;
          line-height: 40px;
          font-size: 16px;
          color: #ffffff;
          border: none;
          border-radius: 4px;
          background: #1c425e;
          cursor: pointer;
        }

        .page-card-right-button:hover {
          color: #ffffff;
        }
      }
    }
  }
}
</style>
